import React from "react";
import { capitalizeCamelCase } from "../utils/commonFunctions";

export default function InputLayout({ label, name, required, col, extraClassName, errorMessage, data, children, maxLength }) {
    return (
        <>
            <div className={`px-1 col-${col} ${extraClassName}`}>
                <div className="flex flex-column gap-2">
                    <div className="flex justify-content-between">
                        <label htmlFor={name} className="text-sm font-semibold">
                            <div>
                                {label ? label : capitalizeCamelCase(name)}
                                {required ? <span className="text-red-500">*</span> : null}
                            </div>
                        </label>

                        {maxLength && (
                            <div>
                                Maxlength: {maxLength - data?.[name].length}/{maxLength}
                            </div>
                        )}
                    </div>
                    {children}
                    {errorMessage || data?.formErrors?.[name] ? <small className="p-error">{errorMessage || data?.formErrors?.[name]}</small> : null}
                </div>
            </div>
        </>
    );
}
