import { types } from "../types/types";

const initialState = {
    progress: 0,
};

const uploadReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.UPDATE_UPLOAD_PROGRESS:
            return {
                ...state,
                progress: action.payload,
            };
        default:
            return state;
    }
};

export default uploadReducer;