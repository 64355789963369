import JSZip from "jszip";
import QRCodeStyling from "qr-code-styling";
import logoNew from "../../assets/images/logoNew.png";
import { getEpoch } from "../../utils/commonFunctions";
import QrTemplate from "../../assets/images/QrTemplate.png";
import { useEffect, useRef, useState } from "react";
import PrimaryButton from "../../shared/Button/PrimaryButton";

let options = {
    width: 420,
    height: 420,
    image: logoNew,
    imageOptions: {
        hideBackgroundDots: true,
        imageSize: 0.3,
        crossOrigin: "anonymous",
    },
    dotsOptions: {
        color: "#222222",
        type: "rounded",
    },
    cornersSquareOptions: {
        color: "#222222",
        type: "extra-rounded",
    },
    cornersDotOptions: {
        color: "#222222",
        type: "dot",
    },
};

const qrCode = new QRCodeStyling({
    ...options,
});

export default function UseCustomQR({ payload }) {
    const ref = useRef(null);

    const [canvasUrl, setCanvasUrl] = useState("");
    const [data, setData] = useState("");
    useEffect(() => {
        setData(payload);
    }, [payload]);

    useEffect(() => {
        qrCode.append(ref.current);
    }, []);

    useEffect(() => {
        qrCode.update({
            data: data.qr,
        });
    }, [data]);

    // const drawTextOnCanvas = (ctx, text, canvasWidth) => {
    //     ctx.font = "20px Arial";
    //     ctx.fillStyle = "black";
    //     ctx.textAlign = "center";
    //     ctx.fillText(text, canvasWidth / 2, 802);
    // };

    useEffect(() => {
        const generateQrOnTemplate = async () => {
            if (data.qr) {
                const qrCodeInstance = new QRCodeStyling({ ...options, data: data.qr });
                await new Promise((resolve) => setTimeout(resolve, 1000));
                const rawData = await qrCodeInstance.getRawData("png");
                const img = new Image();
                img.src = URL.createObjectURL(rawData);

                const QrTempImg = new Image();
                QrTempImg.src = QrTemplate;
                await new Promise((resolve) => {
                    QrTempImg.onload = () => {
                        img.onload = () => {
                            const scaleFactor = 0.5; // Define your scaling factor here

                            const canvas = document.createElement("canvas");
                            canvas.width = QrTempImg.width * scaleFactor;
                            canvas.height = QrTempImg.height * scaleFactor;

                            const ctx = canvas.getContext("2d");
                            ctx.fillStyle = "white";
                            ctx.fillRect(0, 0, canvas.width, canvas.height);
                            ctx.drawImage(QrTempImg, 0, 0, QrTempImg.width * scaleFactor, QrTempImg.height * scaleFactor);
                            ctx.drawImage(
                                img,
                                canvas.width / 2 - (img.width * scaleFactor) / 2,
                                325 * scaleFactor,
                                img.width * scaleFactor,
                                img.height * scaleFactor
                            );
                            drawTextOnCanvas(ctx, data.srNo, canvas.width);

                            setCanvasUrl(canvas.toDataURL("image/png"));
                        };
                        resolve();
                    };
                });
            }
        };
        generateQrOnTemplate();
    }, [data]);

    const drawTextOnCanvas = (ctx, text, canvasWidth) => {
        ctx.font = `${20 * 0.5}px Arial`;
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.fillText(text, canvasWidth / 2, 802 * 0.5);
    };

    const QrTemp = new Image();
    QrTemp.src = QrTemplate;

    const onDownload = async (item) => {
        if (item) {
            const zip = new JSZip();

            const QrTemp = new Image();
            QrTemp.src = QrTemplate;
            const qrCode = new QRCodeStyling({ ...options, data: item.qr });
            await new Promise((resolve) => setTimeout(resolve, 1000));
            const rawData = await qrCode.getRawData("png");
            const img = new Image();
            img.src = URL.createObjectURL(rawData);
            await new Promise((resolve) => {
                img.onload = () => {
                    const scaleFactor = 0.5; // Define your scaling factor here
                    const canvas = document.createElement("canvas");
                    canvas.width = QrTemp.width * scaleFactor;
                    canvas.height = QrTemp.height * scaleFactor;

                    const ctx = canvas.getContext("2d");
                    ctx.fillStyle = "white";
                    ctx.fillRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(QrTemp, 0, 0, QrTemp.width * scaleFactor, QrTemp.height * scaleFactor);
                    ctx.drawImage(
                        img,
                        canvas.width / 2 - (img.width * scaleFactor) / 2,
                        325 * scaleFactor,
                        img.width * scaleFactor,
                        img.height * scaleFactor
                    );
                    drawTextOnCanvas(ctx, item.srNo, canvas.width);
                    const filename = item.srNo;
                    let imgDataUrl = canvas.toDataURL("image/png");
                    imgDataUrl = imgDataUrl.split(",")[1];
                    zip.file(`${filename}.png`, imgDataUrl, { base64: true });
                    resolve(); // Resolve the promise
                };
            });

            const content = await zip.generateAsync({ type: "blob" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(content);
            link.download = `${getEpoch()}.zip`;
            link.click();
            // Reset progress bars after download is complete
        }
    };

    const OnDownloadPNG = async (data) => {
        try {
            await Promise.all(
                data.map(async (item) => {
                    const qrCodeInstance = new QRCodeStyling({ ...options, data: item.qr });
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                    const rawData = await qrCodeInstance.getRawData("png");
                    const img = new Image();
                    img.src = URL.createObjectURL(rawData);

                    const QrTempImg = new Image();
                    QrTempImg.src = QrTemplate;

                    await new Promise((resolve) => {
                        QrTempImg.onload = () => {
                            img.onload = () => {
                                const scaleFactor = 0.5;
                                const canvas = document.createElement("canvas");
                                canvas.width = QrTempImg.width * scaleFactor;
                                canvas.height = QrTempImg.height * scaleFactor;

                                const ctx = canvas.getContext("2d");
                                ctx.fillStyle = "white";
                                ctx.fillRect(0, 0, canvas.width, canvas.height);
                                ctx.drawImage(QrTempImg, 0, 0, QrTempImg.width * scaleFactor, QrTempImg.height * scaleFactor);
                                ctx.drawImage(
                                    img,
                                    canvas.width / 2 - (img.width * scaleFactor) / 2,
                                    325 * scaleFactor,
                                    img.width * scaleFactor,
                                    img.height * scaleFactor
                                );
                                drawTextOnCanvas(ctx, item.srNo, canvas.width);

                                const link = document.createElement("a");
                                link.href = canvas.toDataURL("image/png");
                                link.download = `${item.srNo}.png`;
                                link.click();
                                resolve(); // Resolve the promise
                            };
                        };
                    });
                })
            );
        } catch (error) {
            console.error("Error downloading QR codes:", error);
        }
    };

    console.log(payload, "paylaod");
    return (
        <>
            {canvasUrl && <img src={canvasUrl} alt="QR Code on Template" />}
            <PrimaryButton extraClassName="justify-content-center w-2" onClick={() => onDownload(payload)}>
                Download
            </PrimaryButton>
        </>
    );
}
