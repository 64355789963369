import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constants";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";
import { uploadImages } from "./uploadFiles";

const getVehicleModels = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.VEHICLE_MODELS);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.VEHICLE_MODELS,
                payload: res.data,
            });
        }
    }
    dispatch(hideLoaderAction());
};

const getVehicleModel = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.VEHICLE_MODELS + id);
    if (res.success) {
        if (res.data) {
            if (returnData) {
                returnData(res.data);
            }
        }
    }
    dispatch(hideLoaderAction());
};

const addVehicleModel = (data, next, setLoading) => async (dispatch) => {
    dispatch(showLoaderAction());
    setLoading(true);
    if (data.image && data.image.length) {
        let urls = await uploadImages(data.image);
        data.image = urls[0];
    } else {
        data.image = "";
    }

    const res = await api("post", Constants.END_POINT.VEHICLE_MODELS, data);
    if (res.success) {
        dispatch(showToast({ severity: "success", summary: res.message }));
        next(res.data);
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    setLoading(false);
    dispatch(hideLoaderAction());
};

const editVehicleModel = (id, data, dispatch1, history, setLoading) => async (dispatch) => {
    dispatch(showLoaderAction());
    setLoading(true);
    if (data?.image?.length) {
        let urls = await uploadImages(data.image, dispatch1);
        data.image = urls[0];
    } else {
        data.image = "";
    }

    const res = await api("put", Constants.END_POINT.VEHICLE_MODELS + id, data);
    if (res.success) {
        dispatch(showToast({ severity: "success", summary: res.message }));
        history(-1);
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    setLoading(false);
    dispatch(hideLoaderAction());
};
const importVehicleModel = (data, setOpenImport) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("post", Constants.END_POINT.IMPORT_VEHICLE_MODAL, data);
    if (res.success) {
        dispatch(showToast({ severity: "success", summary: res.message }));
        setOpenImport(false);
        dispatch(getVehicleModels());
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const deleteVehicleModel = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("delete", Constants.END_POINT.VEHICLE_MODELS + id);
    if (res.success) {
        dispatch(getVehicleModels());
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

export { getVehicleModels, getVehicleModel, addVehicleModel, editVehicleModel, deleteVehicleModel, importVehicleModel };
