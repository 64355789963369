import React from "react";
import { getImageURL } from "../../utils/imageUrl";
import dummy from "../../assets/images/profile.png";
import { Image } from "primereact/image";

export default function TableImage({ image }) {
    return (
        <Image
            src={image ? getImageURL(image) : dummy}
            onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = dummy;
            }}
            alt={image}
            width="35"
            height="35"
            preview
        />
    );
}
