import React, { useState, useEffect } from "react";
import { DashBoardCard } from "../../shared/Card/CustomCard";
import { Chart } from "primereact/chart";
import { getDashboard } from "../../redux/actions/dashboardAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
    const dispatch = useDispatch();
    const history = useNavigate();

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Default to current year

    let dashboardData = useSelector((state) => state.dashboard.dashboardData);

    useEffect(() => {
        dispatch(getDashboard(selectedYear));
    }, [dispatch, selectedYear]);

    useEffect(() => {
        if (dashboardData) {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColorSecondary = documentStyle.getPropertyValue("--text-color-secondary");
            const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

            const userChartData = {
                labels: dashboardData?.users?.map((data) => data.monthName),
                datasets: [
                    {
                        label: "Users",
                        data: dashboardData?.users?.map((data) => data.count),
                        fill: false,
                        borderColor: documentStyle.getPropertyValue("--blue-500"),
                        tension: 0.4,
                    },
                    {
                        label: "Revenue",
                        data: dashboardData?.revenue?.map((data) => data.totalRevenue),
                        fill: false,
                        borderColor: documentStyle.getPropertyValue("--pink-500"),
                        tension: 0.4,
                    }
                ],
            };

            const chartOptions = {
                maintainAspectRatio: false,
                aspectRatio: 0.6,
                scales: {
                    x: {
                        ticks: {
                            color: textColorSecondary,
                        },
                        grid: {
                            color: surfaceBorder,
                        },
                    },
                    y: {
                        beginAtZero: true,
                        ticks: {
                            color: textColorSecondary,
                            callback: (value) => {
                                return Number.isInteger(value) ? value : null;
                            },
                        },
                        grid: {
                            color: surfaceBorder,
                        },
                    },
                },
            };

            setChartData(userChartData);
            setChartOptions(chartOptions);
        }
    }, [dashboardData]);

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    return (
        <>
            <div className="grid m-0 p-0 light">
                <DashBoardCard title="Active Users" count={dashboardData.activeUsers ?? 0} onClick={() => history('/user')} />
                <DashBoardCard title="Active Executives" count={dashboardData.activeExecutives ?? 0} onClick={() => history('/executive')} />
                <DashBoardCard title="Total QRs" iconClassName="pi pi-qrcode" count={dashboardData.totalQrCodes ?? 0} onClick={() => history('/qr-code')} />
                <DashBoardCard title="Active QRs" iconClassName="pi pi-qrcode" count={dashboardData.activeQrCodes ?? 0} onClick={() => history('/qr-code?status=ACTIVE')} />
                <DashBoardCard title="Shipping Orders" iconClassName="pi pi-shopping-bag" count={dashboardData.totalShippingOrders ?? 0} onClick={() => history('/shipping-order')} />
                <DashBoardCard title="Replacement Orders" iconClassName="pi pi-briefcase" count={dashboardData.totalReplacementOrders ?? 0} onClick={() => history('/replacement')} />
                <DashBoardCard title="Assigned QRs" iconClassName="pi pi-qrcode" count={dashboardData.assignedQrCodes ?? 0} onClick={() => history('/qr-code?status=ASSIGNED')} />
                <DashBoardCard title="New QRs" iconClassName="pi pi-qrcode" count={dashboardData.newQrCodes ?? 0} onClick={() => history('/qr-code?status=NEW')} />
            </div>
            <div className="grid mt-3 align-items-center">
                <div className="col-12 xl:col-12 z-4">
                    <div className="card">
                        <div className="d-flex justify-content-end mb-3">
                            <div className="col-3">
                                <select className="form-control" value={selectedYear} onChange={handleYearChange}>
                                    {dashboardData?.years?.map((year) => (
                                        <option key={year} value={year}>{year}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <Chart type="line" data={chartData} options={chartOptions} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
