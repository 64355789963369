import api from "../../services/api";
import Constants from "../../services/constants";
import { authenticate, isAuthenticated } from "../../services/auth";
import { types } from "../types/types";
import { showToast } from "./toastAction";

export const loginAction = (data, setLoading, naviagte) => async (dispatch) => {
    setLoading(true);
    const res = await api("post", Constants.END_POINT.LOGIN, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.USER_DETAIL,
                payload: res.data,
            });
            authenticate(res.data.token, () => {
                if (isAuthenticated()) {
                    naviagte("/dashboard");
                }
                if (!isAuthenticated) {
                    naviagte("/login");
                }
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    setLoading(false);
};

export const ForgetPasswordAction = (email, setLoading, setForgetPassword) => async (dispatch) => {
    setLoading(true);
    const payload = {
        email,
    };
    const res = await api("post", Constants.END_POINT.FORGET_PASSWORD, payload);
    if (res.success) {
        if (res.data) {
            setForgetPassword(res.data.email);
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    setLoading(false);
};

export const ResetForgotPassword = (data, setLoading, navigate) => async (dispatch) => {
    setLoading(true);
    const payload = { email: data?.email, otpCode: data?.otpCode, password: data?.password };
    const res = await api("post", Constants.END_POINT.RESET_PASSWORD, payload);
    if (res.success) {
        dispatch(showToast({ severity: "success", summary: res.message }));
        navigate("/");
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    setLoading(false);
};
