import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import TableCard from "../../shared/Table/TableCard";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";

export default function ShippingTable({ heading, allShippingOrder, loading, history, search, setSearch }) {
    const [selectedRow, setSelectedRow] = useState([]);
    const actions = (d) => {
        return (
            <div className="flex justify-content-around">
                <i className="pi pi-eye cursor-pointer text-dark" onClick={() => history(`/shipping-order/${d._id}`)}></i>
            </div>
        );
    };

    const filters = {
        time: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        subscriptionExpireOn: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    };

    const formatDate = (date) => {
        return date?.toLocaleDateString?.("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
    };

    const dateFilterTemplate = (options) => {
        return (
            <Calendar
                value={options.value}
                onChange={(e) => {
                    options.filterCallback(e.value, options.index);
                }}
                dateFormat="mm/dd/yy"
                placeholder="mm/dd/yyyy"
                mask="99/99/9999"
            />
        );
    };

    const orderSrNo = (r) => {
        const srNo = r.srNo ? r.srNo : r._id;
        return srNo;
    };

    return (
        <>
            <div className="col-12">
                <div className="card">
                    <div className="flex justify-content-between mb-4">
                        <h5>{heading}</h5>
                        {setSearch ? (
                            <div className="p-input-icon-left mx-2">
                                <i className="pi pi-search" />
                                <InputText value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search" />
                            </div>
                        ) : null}
                    </div>
                    <DataTable
                        paginator
                        value={allShippingOrder}
                        selection={selectedRow}
                        onSelectionChange={(e) => setSelectedRow(e.value)}
                        selectionMode="checkbox"
                        showGridlines
                        filter={filters}
                        filterDisplay="menu"
                        rows={10}
                        loading={loading}
                        emptyMessage="No Order found."
                        rowClassName={(rowData) => (rowData.isNewRecord ? "p-highlight p-selectable-row" : "")}
                    >
                        <Column body={orderSrNo} header="Order ID "></Column>
                        <Column
                            field="time"
                            header="Date"
                            style={{ width: "150px" }}
                            dataType="date"
                            filterMatchMode="dateIs"
                            showFilterMatchModes={true}
                            body={(rowData) => formatDate(rowData.time)}
                            filter
                            filterElement={dateFilterTemplate}
                        ></Column>
                        <Column field="user.userId" header="User Id" style={{ width: "250px" }}></Column>
                        <Column field="vehicle.vehicleNumber" header="Vehicle Number" style={{ width: "250px" }}></Column>
                        <Column field="status" header="Status"></Column>

                        <Column body={actions} style={{ width: "80px" }} header="Action"></Column>
                    </DataTable>
                </div>
            </div>
        </>
    );
}
