import { types } from "../types/types";
const intitalState = {
    allShippingOrder: [],
};

const shippingOrderReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.CHANGE_SHIPPING_ORDERS:
            return {
                ...state,
                allShippingOrder: action.payload,
            };
        default:
            return { ...state };
    }
};

export default shippingOrderReducer;
