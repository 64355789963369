import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constants";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";

const getUsers = (setLoading) => async (dispatch) => {
    dispatch(showLoaderAction());
    if (setLoading) {
        setLoading(true);
    }
    const res = await api("get", Constants.END_POINT.USER);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CHANGE_USER,
                payload: res.data,
            });
        }
    }
    if (setLoading) {
        setLoading(false);
    }
    dispatch(hideLoaderAction());
};
const getUser = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.USER + id);
    if (res.success) {
        if (res.data) {
            if (returnData) {
                returnData(res.data);
            }
        }
    }
    dispatch(hideLoaderAction());
};
const userDeleteAction = (id, data) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("delete", Constants.END_POINT.USER + id);
    if (res.success) {
        dispatch(getUsers());
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};
const disableUser = (id, isBlocked) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("delete", Constants.END_POINT.USER_DISABLE + id, { isBlocked });
    if (res.success) {
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};
export { getUsers, getUser, userDeleteAction, disableUser };
