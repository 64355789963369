import { combineReducers } from "redux";
import loaderReducer from "./loaderReducer";
import profileReducer from "./profileReducer";
import toastReducer from "./toastReducer";
import uploadReducer from "./uploadReducer";
import plansReducer from "./plansReducer";
import userReducer from "./userReducer";
import replacementReducer from "./replacementReducer";
import shippingOrderReducer from "./shippingOrderReducer";
import qrCodeReducer from "./qrCodeReducer";
import supportQueriesReducer from "./supportQueriesReducer";
import executiveReducer from "./executiveReducer";
import vehicleBrandReducer from "./vehicleBrandReducer";
import vehicleModelReducer from "./vehicleModelReducer";
import staticPageReducer from "./staticPageReducer";
import dashboardReducer from "./dashboardReducer";
import callLogsReducer from "./callLogsReducer";
import expiredMembershipsReducer from "./expiredMembershipsReducer";

export default combineReducers({
    toast: toastReducer,
    loader: loaderReducer,
    profile: profileReducer,
    upload: uploadReducer,
    plans: plansReducer,
    user: userReducer,
    executive: executiveReducer,
    replacement: replacementReducer,
    shippingOrder: shippingOrderReducer,
    qrCode: qrCodeReducer,
    supportQueries: supportQueriesReducer,
    vehicleBrand: vehicleBrandReducer,
    vehicleModel: vehicleModelReducer,
    staticPage: staticPageReducer,
    dashboard: dashboardReducer,
    callLogs: callLogsReducer,
    expiredMemberships: expiredMembershipsReducer,
});
