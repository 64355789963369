import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import PrimaryButton from "../../shared/Button/PrimaryButton";
import CustomDialog from "../../shared/Layouts/CustomDialog";
import { CustomGridLayout } from "../../shared/Layouts/FormPage";
import { CustomCheckBox, CustomDropDown, CustomInput } from "../../shared/AllInput";
import { useDispatch, useSelector } from "react-redux";
import { addQrCodeAction, assignQRtoExecutive, getQRList } from "../../redux/actions/qrCodeAction";
import useCustomQRs from "./useCustomQRs";
import { useParams } from "react-router-dom";
import { Tag } from "primereact/tag";
import { FilterMatchMode } from "primereact/api";
import { getExecutives } from "../../redux/actions/executiveAction";
import { Paginator } from "primereact/paginator";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";

export default function QRCodeTable({
    heading,
    allQRCode,
    loading,
    history,
    search,
    setSearch,
    setLoading,
    page,
    limit,
    totalRecords,
    setPage,
    setLimit,
    setSelectedStatus,
    selectedStatus,
}) {
    const { id } = useParams();
    const [selectedRow, setSelectedRow] = useState([]);
    const [visible, setVisible] = useState(false);
    const [visibleDialog, setVisibleDialog] = useState(false);
    const dispatch = useDispatch();

    const [statuses] = useState(["NEW", "ASSIGNED", "ACTIVE", "INACTIVE"]);
    const [currentPage, setCurrentPage] = useState(page);
    const [pageSize, setPageSize] = useState(limit);

    let allExecutives = useSelector((state) => state.executive.allExecutives);

    useEffect(() => {
        dispatch(getExecutives());
    }, [dispatch]);

    const getSeverity = (status) => {
        switch (status) {
            case "ACTIVE":
                return "success";
            case "ASSIGNED":
                return "info";
            case "NEW":
                return "warning";
            case "INACTIVE":
                return "danger";
            default:
                return null;
        }
    };

    const [data, setData] = useState({
        quantity: "",
        isDownload: true,
        assignedTo: "",
    });

    const handleChange = ({ name, value }) => {
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const actions = (d) => {
        return (
            <div className="flex justify-content-around">
                <i className="pi pi-eye cursor-pointer text-dark" onClick={() => history(`/qr-code/view/${d._id}`)}></i>
            </div>
        );
    };

    const dateFilterTemplate = (options) => {
        return (
            <Calendar
                value={options.value}
                onChange={(e) => {
                    options.filterCallback(e.value, options.index);
                }}
                dateFormat="mm/dd/yy"
                placeholder="mm/dd/yyyy"
                mask="99/99/9999"
            />
        );
    };

    const filters = {
        time: { operator: "and", constraints: [{ value: null, matchMode: "contains" }] },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
    };

    const formatDate = (date) => {
        return date.toLocaleDateString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
    };

    const onClose = () => {
        setVisible(false);
    };

    const onCloseDialog = () => {
        setVisibleDialog(false);
    };

    let { zipProgress, creationProgress, OnDownload, OnDownloadPNG } = useCustomQRs();

    const handleSave = () => {
        dispatch(
            addQrCodeAction(data, (res) => {
                data.isDownload === true && OnDownload(res);
                onClose();
                dispatch(getQRList(page, limit, search, selectedStatus));
            })
        );
    };

    const handleAssign = () => {
        if (!data.assignedTo) return;
        const assignedId = data?.assignedTo?._id;
        const qrCodes = selectedRow?.map((item) => {
            return item._id;
        });
        dispatch(
            assignQRtoExecutive(assignedId, qrCodes, () => {
                onCloseDialog();
                dispatch(getQRList(page, limit, search));
            })
        );
        setSelectedRow([]);
    };

    const userDetails = (r) => {
        const userId = r?.vehicle?.user?.userId;
        const userDBId = r?.vehicle?.user?._id;
        return (
            (
                <span style={{ cursor: "pointer" }} className="link" onClick={() => history(`/user/view/${userDBId}`)}>
                    {userId}
                </span>
            ) ?? null
        );
    };

    const executiveDetails = (r) => {
        const executiveEmail = r?.assignedTo?.email;
        const executiveId = r?.assignedTo?._id;

        return (
            (
                <span style={{ cursor: "pointer" }} className="link" onClick={() => history(`/executive/view/${executiveId}`)}>
                    {executiveEmail}
                </span>
            ) ?? null
        );
    };

    const vehicleNumber = (r) => {
        const data = r?.vehicle?.vehicleNumber;
        return data ?? null;
    };

    const statusFilterTemplate = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={statuses}
                onChange={(e) => options.filterApplyCallback(e.value)}
                itemTemplate={statusItemTemplate}
                placeholder="Select One"
                className="p-column-filter"
                showClear
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.status} severity={getSeverity(rowData.status)} />;
    };

    // Function to handle pagination change
    const onPageChange = (event) => {
        setCurrentPage(event.first / event.rows + 1);
        setPage(event.page + 1);
        setLimit(event.rows);
        setPageSize(event.rows);
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };

    const statusOptions = [
        { name: selectedStatus ? "All" : "Select Status", value: "" },
        { name: "Active", value: "ACTIVE" },
        { name: "Assigned", value: "ASSIGNED" },
        { name: "New", value: "NEW" },
        { name: "Inactive", value: "INACTIVE" },
    ];

    return (
        <>
            <div className="col-12">
                <div className="card">
                    <div className="flex justify-content-between mb-4">
                        <h5>{heading}</h5>
                        {setSearch ? (
                            <div className="flex align-items-center">
                                <Dropdown
                                    value={selectedStatus}
                                    onChange={(e) => setSelectedStatus(e.value)}
                                    optionLabel="name"
                                    placeholder={"Select Status"}
                                    options={statusOptions}
                                    className="mr-2"
                                    style={{ width: "10rem" }}
                                />
                                <div className="p-input-icon-left mx-2">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search" />
                                </div>
                                {!id && <PrimaryButton onClick={() => setVisible(true)}>Add Qr Code</PrimaryButton>}
                                {selectedRow?.length > 0 && (
                                    <>
                                        <PrimaryButton onClick={() => OnDownloadPNG(selectedRow)}>Download</PrimaryButton>
                                        {!id && <PrimaryButton onClick={() => setVisibleDialog(selectedRow)}>Assign QRs</PrimaryButton>}
                                    </>
                                )}
                            </div>
                        ) : null}
                    </div>
                    <Dialog header="" className="" visible={creationProgress || zipProgress} contentClassName="" style={{ width: "50vw" }}>
                        {creationProgress > 0 && (
                            <>
                                <h4>Creating</h4>
                                <ProgressBar value={creationProgress} className="mb-5" />
                            </>
                        )}
                        {zipProgress > 0 && (
                            <>
                                <h4>Downloading</h4>
                                <ProgressBar value={zipProgress} className="mb-5" />
                            </>
                        )}
                    </Dialog>

                    <DataTable
                        // paginator
                        value={allQRCode}
                        selection={selectedRow}
                        onSelectionChange={(e) => setSelectedRow(e.value)}
                        selectionMode="checkbox"
                        filters={filters}
                        emptyMessage="No QRCode found."
                        className=""
                        showGridlines
                        first={(currentPage - 1) * pageSize}
                        rows={pageSize}
                        totalRecords={totalRecords || 0}
                        onPage={onPageChange}
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                        <Column field="srNo" header="QR Code"></Column>
                        <Column body={userDetails} header="UserId"></Column>
                        <Column body={executiveDetails} header="Assigned To"></Column>
                        <Column body={vehicleNumber} header="Vehicle Number"></Column>
                        <Column field="status" header="Status" body={statusBodyTemplate}></Column>
                        <Column
                            header="Date"
                            field="time"
                            dataType="date"
                            style={{ width: "150px" }}
                            filterMatchMode="dateIs"
                            showFilterMatchModes={true}
                            body={(rowData) => formatDate(rowData.time)}
                            filter
                            filterElement={dateFilterTemplate}
                        />
                        <Column body={actions} style={{ width: "80px" }} header="Action"></Column>
                    </DataTable>
                    <Paginator
                        first={(currentPage - 1) * pageSize}
                        rows={pageSize}
                        totalRecords={totalRecords || 0}
                        onPageChange={onPageChange}
                        rowsPerPageOptions={[10, 20, 30]}
                    />
                </div>
            </div>

            <CustomDialog title="Add QRCode" visible={visible} onCancel={onClose} loading={loading} onSave={handleSave}>
                <CustomGridLayout>
                    <CustomInput col="12" name="quantity" label="Number Of QR" data={data} onChange={handleChange} keyfilter="int" />
                    <CustomCheckBox col="12" name="isDownload" label="Download QR on Save?" data={data} onChange={handleChange} />
                </CustomGridLayout>
            </CustomDialog>

            <CustomDialog title="Assign QRCode" visible={visibleDialog} onCancel={onCloseDialog} loading={loading} onSave={handleAssign}>
                <CustomGridLayout>
                    <CustomDropDown
                        col={12}
                        data={data}
                        options={allExecutives}
                        label={"Executive"}
                        name="assignedTo"
                        optionLabel={"firstName" ? "firstName" : "email"}
                        onChange={handleChange}
                    />
                </CustomGridLayout>
            </CustomDialog>
        </>
    );
}
