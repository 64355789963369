import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import TableImage from "../../shared/Table/TableImage";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import PrimaryButton from "../../shared/Button/PrimaryButton";
import { Paginator } from "primereact/paginator";
import moment from "moment";
import { Tag } from "primereact/tag";

export default function CallLogsTable({ heading, allCallLogs, loading, history, search, setSearch, page, limit, totalRecords, setPage, setLimit }) {
    const [selectedRow, setSelectedRow] = useState([]);
    const [currentPage, setCurrentPage] = useState(page);
    const [pageSize, setPageSize] = useState(limit);

    // const actions = (d) => {
    //     return (
    //         <div className="flex justify-content-around">
    //             <i className="pi pi-eye cursor-pointer text-dark" onClick={() => history(`/call-logs/view/${d._id}`)}></i>
    //         </div>
    //     );
    // };

    const dateFilterTemplate = (options) => {
        return (
            <Calendar
                value={options.value}
                onChange={(e) => {
                    options.filterCallback(e.value, options.index);
                }}
                dateFormat="mm/dd/yy"
                placeholder="mm/dd/yyyy"
                mask="99/99/9999"
            />
        );
    };

    const filters = {
        time: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        subscriptionExpireOn: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    };

    const formatDate = (date) => {
        return moment(date).format('MMMM Do YYYY, h:mm:ss a')

        // return date?.toLocaleDateString?.("en-US", {
        //     day: "2-digit",
        //     month: "2-digit",
        //     year: "numeric",
        // });
    };

    const caller = (data) => {
        const userId = data?.caller?.userId
        const userDBId = data?.caller?._id
        const userPhone = data?.caller?.countryCode && data?.caller?.mobile
            ? `${data.caller.countryCode}${data.caller.mobile}`
            : null;

        return (
            <span style={{ cursor: 'pointer' }} className="link" onClick={() => history(`/user/view/${userDBId}`)}>
                {userId} {userPhone ? `(${userPhone})` : null}
            </span>
        ) ?? null;
    }

    const reciever = (data) => {
        const userId = data?.reciever?.userId
        const userDBId = data?.reciever?._id
        const userPhone = data?.reciever?.countryCode && data?.reciever?.mobile
            ? `${data.reciever.countryCode}${data.reciever.mobile}`
            : null;

        return (
            <span style={{ cursor: 'pointer' }} className="link" onClick={() => history(`/user/view/${userDBId}`)}>
                {userId} {userPhone ? `(${userPhone})` : null}
            </span>
        ) ?? null;
    }

    const recieverVehicle = (data) => {
        const vehicleNumber = data?.vehicle?.vehicleNumber

        return vehicleNumber ?? null

    }

    // Function to handle pagination change
    const onPageChange = (event) => {
        setCurrentPage(event.first / event.rows + 1);
        setPage(event.page + 1)
        setLimit(event.rows)
        setPageSize(event.rows);
    };

    const getSeverity = (status) => {
        switch (status) {
            case 'COMPLETED':
                return { rowSeverity: 'success', value: 'Active' };
            case 'NOTANSWERED':
                return { rowSeverity: 'info', value: 'Not Answered' };
            case 'ACTIVE':
                return { rowSeverity: 'warning', value: 'Rejected' };
            case 'REJECTED':
                return { rowSeverity: 'danger', value: 'Rejected' };
            default:
                return { rowSeverity: null, value: null };
        }
    };

    const callStatusTemplate = (rowData) => {
        const { rowSeverity, value } = getSeverity(rowData.status)
        return <Tag value={value} severity={rowSeverity} />;
    };

    return (
        <>
            <div className="col-12">
                <div className="card">
                    <div className="flex justify-content-between mb-4">
                        <h5>{heading}</h5>
                        {setSearch ? (
                            <div className="p-input-icon-left mx-2">
                                <i className="pi pi-search" />
                                <InputText value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search" />
                            </div>
                        ) : null}
                    </div>
                    <DataTable
                        // paginator
                        value={allCallLogs}
                        selection={selectedRow}
                        onSelectionChange={(e) => setSelectedRow(e.value)}
                        selectionMode="checkbox"
                        filter={filters}
                        filterDisplay="menu"
                        loading={loading}
                        emptyMessage="No Call Logs found."
                        className=""
                        showGridlines
                        first={(currentPage - 1) * pageSize}
                        rows={pageSize}
                        totalRecords={totalRecords || 0}
                        onPage={onPageChange}
                    >
                        <Column body={caller} header="Caller"></Column>
                        <Column body={reciever} header="Reciever"></Column>
                        <Column body={recieverVehicle} header="Reciever's Vehicle Number"></Column>
                        <Column body={callStatusTemplate} header="Call Status"></Column>
                        <Column
                            header="Date and Time"
                            field="time"
                            dataType="date"
                            // style={{ width: "150px" }}
                            filterMatchMode="dateIs"
                            showFilterMatchModes={true}
                            body={(rowData) => formatDate(rowData.time)}
                            filter
                            filterElement={dateFilterTemplate}
                        />
                        {/* <Column body={actions} style={{ width: "80px" }} header="Action"></Column> */}
                    </DataTable>
                    <Paginator
                        first={(currentPage - 1) * pageSize}
                        rows={pageSize}
                        totalRecords={totalRecords || 0}
                        onPageChange={onPageChange}
                        rowsPerPageOptions={[10, 20, 30]}
                    />
                </div>
            </div>
        </>
    );
}
