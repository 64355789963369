import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import VehicleBrandTable from "./VehicleBrandTable";
import { getVehicleBrands, deleteVehicleBrand } from "../../redux/actions/vehicleBrandAction";
import { confirmDelete, customConfirmPopup } from "../../utils/commonFunctions";

const VehicleBrand = () => {
    const history = useNavigate();
    const dispatch = useDispatch();

    let allVehicleBrands = useSelector((state) => state.vehicleBrand.allVehicleBrands);

    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");

    useEffect(() => {
        dispatch(getVehicleBrands(setLoading));
    }, [dispatch]);

    const getFilteredData = (data, search) => {
        if (search.length) {
            data = data?.filter(
                (item) =>
                    item?.name?.toLowerCase().includes(search.toLowerCase())
            );
        }
        return data;
    };

    allVehicleBrands = getFilteredData(allVehicleBrands, search);
    allVehicleBrands = allVehicleBrands.map((item) => ({
        ...item,
        time: new Date(item.time),
    }));

    const onDelete = (id) => {
        customConfirmPopup(
            () => dispatch(deleteVehicleBrand(id)),
            "Do you want to delete this record?",
        );
    };


    return <VehicleBrandTable heading="All Vehicle Brands" setLoading={setLoading} allVehicleBrands={allVehicleBrands} loading={loading} history={history} search={search} setSearch={setSearch} onDelete={onDelete} />;
};

export default VehicleBrand;
