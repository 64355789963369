export const types = {
    SHOW_TOAST: "SHOW_TOAST",
    SHOW_LOADER: "SHOW_LOADER",
    HIDE_LOADER: "HIDE_LOADER",
    SHOW_SIDEBAR_LOADER: "SHOW_SIDEBAR_LOADER",
    HIDE_SIDEBAR_LOADER: "HIDE_SIDEBAR_LOADER",
    UPDATE_UPLOAD_PROGRESS: "UPDATE_UPLOAD_PROGRESS",
    USER_DETAIL: "USER_DETAIL",
    CHANGE_USER: "CHANGE_USER",
    EXPIRED_MEMBERSHIPS: "EXPIRED_MEMBERSHIPS",
    CHANGE_EXECUTIVE: "CHANGE_EXECUTIVE",
    CHANGE_REPLCAMENT: "CHANGE_REPLCAMENT",
    CHANGE_SHIPPING_ORDERS: "CHANGE_SHIPPING_ORDERS",
    CHANGE_QR_CODE: "CHANGE_QR_CODE",
    CHANGE_UNASSIGNED_QR_CODE: "CHANGE_UNASSIGNED_QR_CODE",
    SUPPORT_QUERIES: "SUPPORT_QUERIES",
    VEHICLE_BRANDS: "VEHICLE_BRANDS",
    VEHICLE_MODELS: "VEHICLE_MODELS",
    STATIC_PAGES: "STATIC_PAGES",
    DASHBOARD: "DASHBOARD",
    CALL_LOGS: "CALL_LOGS",
    NEW_ORDERS_COUNT: "NEW_ORDERS_COUNT",
    EXECUTIVE_PAYROLL: "EXECUTIVE_PAYROLL",
};
