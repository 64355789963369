import React from "react";
import { AutoComplete } from "primereact/autocomplete";
import InputLayout from "../InputLayout";

export default function CustomAutocomplete(
    {
        value,
        label,
        suggestions,
        forceSelection = false,
        onChange,
        data,
        name,
        extraClassName,
        field = undefined,
        dropdown = false,
        completeMethod,
        required,
        errorMessage,
        col,
        inputClass
    }) {
    return (
        <>
            <InputLayout
                col={col || 6}
                label={label}
                name={name}
                required={required}
                extraClassName={extraClassName}
                data={data}
                errorMessage={errorMessage}
            >
                <AutoComplete
                    value={value || data?.[name]}
                    className={`w-full ${inputClass ? inputClass : ""} ${errorMessage ? "p-invalid" : ""}`}
                    forceSelection={forceSelection}
                    virtualScrollerOptions={{ itemSize: 38 }}
                    suggestions={suggestions}
                    field={field}
                    name={name}
                    // onChange={onChange}
                    onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.value })}
                    dropdown={dropdown}
                    completeMethod={completeMethod}
                />
            </InputLayout>
        </>
    );
}
