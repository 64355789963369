import React from "react";
import { Button } from "primereact/button";

export default function PrimaryButton({ type, onClick, label, loading, extraClassName, ...props }) {
    return <Button type={type} label={label} onClick={onClick} loading={loading} className={`mx-1  ${extraClassName}`} {...props} />;
}
export function LightButton({ className, ...props }) {
    return <Button className={`my-2 btn-lightblue  ${className}`} {...props} />;
}

export function CustomButtonGroup({ col = "12", position = "end", children, ...props }) {
    return (
        <div className={`col-12 md:col-${col}`}>
            <div className={`flex justify-content-${position}`}>{children}</div>
        </div>
    );
}

export function PrimaryButtonOutlined({ type, onClick, label, loading, extraClassName, ...props }) {
    return (
        <Button
            type={type}
            label={label}
            onClick={onClick}
            loading={loading}
            className={`mx-1 p-button-outlined my-2 ${extraClassName}`}
            {...props}
        />
    );
}
