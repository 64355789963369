import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constants";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";

const getShippingOrder = (setLoading) => async (dispatch) => {
    if (setLoading) {
        setLoading(true);
    }
    const res = await api("get", Constants.END_POINT.SHIPPING_ORDER);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CHANGE_SHIPPING_ORDERS,
                payload: res.data,
            });
        }
    }
    if (setLoading) {
        setLoading(false);
    }
};
const viewShippingOrder = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.SHIPPING_ORDER + id);
    if (res.success) {
        if (res.data) {
            if (returnData) {
                returnData(res.data);
            }
        }
    }
    dispatch(hideLoaderAction());
};

const updateOrderStatus = (id, status) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("put", Constants.END_POINT.SHIPPING_ORDER + id, { status });
    if (res.success) {
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

export { getShippingOrder, viewShippingOrder, updateOrderStatus };
