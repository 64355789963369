import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addVehicleModel, editVehicleModel, getVehicleModel, getVehicleModels } from "../../../redux/actions/vehicleModelAction";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";
import { getVehicleBrands } from "../../../redux/actions/vehicleBrandAction";

function VehicleModelContainer() {
    const history = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    const [data, setData] = useState({
        image: [],
        name: "",
        vehicleBrand: "",
    });
    const [formType, setFormType] = useState("EDIT");
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");

    let allVehicleModels = useSelector((state) => state.vehicleModel.allVehicleModels);
    const allVehicleBrands = useSelector((state) => state.vehicleBrand.allVehicleBrands);

    useEffect(() => {
        dispatch(getVehicleBrands());

        if (id) {
            dispatch(
                getVehicleModel(id, (data) => {
                    setData({
                        image: data.image ? [data.image] : [],
                        name: data.name,
                        vehicleBrand: data.vehicleBrand,
                    });
                })
            );
        } else {
            dispatch(getVehicleModels());
        }
    }, [id, dispatch]);

    const handleChange = ({ name, value }) => {
        if (formType !== "VIEW") {
            const formErrors = allValidations(name, value, data);
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const onSubmit = () => {
        if (showFormErrors(data, setData)) {
            if (formType === "ADD") {
                dispatch(addVehicleModel(data, () => history("/vehicle-model"), setLoading));
            } else if (formType === "EDIT") {
                dispatch(editVehicleModel(id, data, dispatch, history, setLoading));
            }
        }
    };

    const getFilteredData = (data, search) => {
        if (search.length) {
            data = data?.filter((item) => item?.name?.toLowerCase().includes(search.toLowerCase()));
        }
        return data;
    };

    allVehicleModels = getFilteredData(allVehicleModels, search);
    allVehicleModels = allVehicleModels?.map((item) => ({
        ...item,
        time: new Date(item.time),
    }));

    return { data, handleChange, loading, onSubmit, setFormType, allVehicleBrands };
}

export default VehicleModelContainer;
