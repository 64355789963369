import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constants";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";
import { uploadImages } from "./uploadFiles";

const getCallLogs = (page, limit, search) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", `${Constants.END_POINT.CALL_LOGS}?page=${page}&limit=${limit}&search=${search}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CALL_LOGS,
                payload: res.data,
            });
        }
    }
    dispatch(hideLoaderAction());
};

const getCallLog = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.CALL_LOGS + id);
    if (res.success) {
        if (res.data) {
            if (returnData) {
                returnData(res.data);
            }
        }
    }
    dispatch(hideLoaderAction());
};

export { getCallLogs, getCallLog };
