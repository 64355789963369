import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constants";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";
import { uploadImages } from "./uploadFiles";

const getStaticPages = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.STATIC_PAGES);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.STATIC_PAGES,
                payload: res.data,
            });
        }
    }
    dispatch(hideLoaderAction());
};

const getStaticPage = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.STATIC_PAGES + id);
    if (res.success) {
        if (res.data) {
            if (returnData) {
                returnData(res.data);
            }
        }
    }
    dispatch(hideLoaderAction());
};

const addStaticPage =
    (data, next = () => {}, setLoading, dispatch1) =>
    async (dispatch) => {
        dispatch(showLoaderAction());
        setLoading(true);

        if (data?.image?.length) {
            let urls = await uploadImages(data.image, dispatch1);
            data.image = urls[0];
        } else {
            data.image = "";
        }

        const res = await api("post", Constants.END_POINT.STATIC_PAGES, data);
        if (res.success) {
            dispatch(showToast({ severity: "success", summary: res.message }));
            next();
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
        }
        setLoading(false);
        dispatch(hideLoaderAction());
    };

const editStaticPage = (id, data, dispatch1, history, setLoading) => async (dispatch) => {
    dispatch(showLoaderAction());
    setLoading(true);
    if (data?.image?.length) {
        let urls = await uploadImages(data.image, dispatch1);
        data.image = urls[0];
    } else {
        data.image = "";
    }

    const res = await api("put", Constants.END_POINT.STATIC_PAGES + id, data);
    if (res.success) {
        dispatch(showToast({ severity: "success", summary: res.message }));
        history(-1);
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    setLoading(false);
    dispatch(hideLoaderAction());
};

const deleteStaticPage = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("delete", Constants.END_POINT.STATIC_PAGES + id);
    if (res.success) {
        dispatch(getStaticPages());
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

export { getStaticPages, getStaticPage, addStaticPage, editStaticPage, deleteStaticPage };
