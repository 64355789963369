import React, { useEffect } from 'react'
import FormPage from '../../../shared/Layouts/FormPage'
import VehicleBrandContainer from './VehicleBrandContainer';
import CustomImageInput from '../../../shared/Input/CustomImageInput';
import PrimaryButton from '../../../shared/Button/PrimaryButton';
import { CustomDropDown, CustomForm, CustomInput } from '../../../shared/AllInput';

function VehicleBrandForm({ type }) {
    const { data, handleChange, loading, onSubmit, setFormType, vehicleTypes } = VehicleBrandContainer();

    useEffect(() => {
        setFormType(type);
    }, [type, setFormType]);

    return (
        <>
            <FormPage>
                <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
                    <div className="col-12 md:col-6">
                        <div className="card">
                            <div className="flex align-items-center justify-content-between">
                                <h3>Vehicle Brand Information</h3>
                            </div>
                            <hr />
                            <div className="grid">
                                <CustomImageInput
                                    name="image"
                                    label="Image"
                                    col={12}
                                    onFilesChange={handleChange}
                                    data={data}
                                    editable={type !== "VIEW" ? true : false}
                                />
                                <CustomInput col={12} label="Name" name="name" required data={data} onChange={handleChange} />
                                <CustomDropDown col={12} data={data} options={vehicleTypes} label={'Vehicle Type'} name='vehicleType' onChange={handleChange} />
                                {type !== "VIEW" &&
                                    <div className='my-2'><PrimaryButton label="Save Vehicle Brand" loading={loading} onClick={onSubmit} col={6} /></div>}
                            </div>
                        </div>
                    </div>
                </CustomForm>
            </FormPage>
        </>
    )
}

export default VehicleBrandForm