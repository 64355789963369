import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import TableImage from "../../shared/Table/TableImage";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

export default function UserTable({ heading, allUsers, loading, history, search, setSearch, deleteUser }) {
    const [selectedRow, setSelectedRow] = useState([]);
    const [visible, setVisible] = useState(false);

    const actions = (d) => {
        return (
            <div className="flex justify-content-around">
                <i className="pi pi-eye cursor-pointer text-dark" onClick={() => history(`/user/view/${d._id}`)}></i>
                <i className="pi pi-trash cursor-pointer text-dark" onClick={() => setVisible(true)}></i>
            </div>
        );
    };

    const image = (r) => {
        const image = r.image;
        return <TableImage image={image} />;
    };
    const dateFilterTemplate = (options) => {
        return (
            <Calendar
                value={options.value}
                onChange={(e) => {
                    options.filterCallback(e.value, options.index);
                }}
                dateFormat="mm/dd/yy"
                placeholder="mm/dd/yyyy"
                mask="99/99/9999"
            />
        );
    };
    const filters = {
        time: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    };
    const formatDate = (date) => {
        return date.toLocaleDateString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
    };

    const footerContent = (
        <div>
            <Button label="No" icon="pi pi-times" onClick={() => setVisible(false)} />
            <Button label="Yes" icon="pi pi-check" onClick={() => { setVisible(false); deleteUser(selectedRow?.[0]?._id) }} autoFocus />
        </div>
    );

    return (
        <>
            <Dialog dismissableMask={true} visible={visible} showHeader={false} draggable={false} closable={false} style={{ width: '40vw' }} onHide={() => { if (!visible) return; setVisible(false); }} footer={footerContent} >
                <h5 className="mt-5 font-bold">
                    Are you sure you want to delete this record?
                </h5>
                <p className="mt-5">
                    User Id: {selectedRow?.[0]?.userId}
                </p>
            </Dialog>

            <div className="col-12">
                <div className="card">
                    <div className="flex justify-content-between mb-4">
                        <h5>{heading}</h5>
                        {setSearch ? (
                            <div className="p-input-icon-left mx-2">
                                <i className="pi pi-search" />
                                <InputText value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search" />
                            </div>
                        ) : null}
                    </div>
                    <DataTable
                        paginator
                        value={allUsers}
                        selection={selectedRow}
                        onSelectionChange={(e) => setSelectedRow(e.value)}
                        selectionMode="checkbox"
                        filter={filters}
                        filterDisplay="menu"
                        rows={10}
                        loading={loading}
                        emptyMessage="No user found."
                        className="custom-datatable-header"
                        showGridlines
                    >
                        {/* <Column body={(rowData) => (rowData.firstName ? rowData.firstName + " " + rowData.lastName : "-")} header="Name"></Column> */}
                        <Column field="userId" header="User ID"></Column>
                        <Column field="email" header="Email"></Column>
                        <Column field="mobile" header="Mobile"></Column>
                        <Column
                            header="Registered Date"
                            field="time"
                            dataType="date"
                            filterMatchMode="dateIs"
                            showFilterMatchModes={true}
                            body={(rowData) => formatDate(rowData.time)}
                            filter
                            filterElement={dateFilterTemplate}
                            style={{ width: "150px" }}
                        />
                        <Column body={image} header="Image" style={{ width: "80px" }}></Column>
                        <Column body={actions} style={{ width: "80px" }} header="Action"></Column>
                    </DataTable>
                </div>
            </div>
        </>
    );
}
