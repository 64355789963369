import { types } from "../types/types";
const intitalState = {
    allExpiredMemberships: [],
};

const expiredMembershipsReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.EXPIRED_MEMBERSHIPS:
            return {
                ...state,
                allExpiredMemberships: action.payload,
            };
        default:
            return { ...state };
    }
};

export default expiredMembershipsReducer;
