import React, { useEffect, useState } from "react";
import UserTable from "./UserTable";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUsers, userDeleteAction } from "../../redux/actions/userAction";

const Users = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUsers(setLoading));
    }, [dispatch]);

    let allUsers = useSelector((state) => state.user.allUsers);

    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");

    const getFilteredData = (data, search) => {
        if (search.length) {
            data = data?.filter(
                (item) =>
                    item?.userId?.includes(search) ||
                    item?.mobile?.includes(search) ||
                    item?.email?.includes(search)
            );
        }
        return data;
    };

    const handleDeleteUser = async (id) => {
        await dispatch(userDeleteAction(id))
    }

    allUsers = getFilteredData(allUsers, search);
    allUsers = allUsers.map((item) => ({
        ...item,
        time: new Date(item.time),
    }));

    return <UserTable heading="All Users" allUsers={allUsers} loading={loading} history={history} search={search} setSearch={setSearch} deleteUser={handleDeleteUser} />;
};

export default Users;
