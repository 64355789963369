import JSZip from "jszip";
import QRCodeStyling from "qr-code-styling";
import logoNew from "../../assets/images/logoNew.png";
import { getEpoch } from "../../utils/commonFunctions";
import { useState } from "react";
import QrTemplate from "../../assets/images/QrTemplate.png";

let options = {
    width: 420,
    height: 420,
    image: logoNew,
    imageOptions: {
        hideBackgroundDots: true,
        imageSize: 0.3,
        crossOrigin: "anonymous",
    },
    dotsOptions: {
        color: "#222222",
        type: "rounded",
    },
    cornersSquareOptions: {
        color: "#222222",
        type: "extra-rounded",
    },
    cornersDotOptions: {
        color: "#222222",
        type: "dot",
    },
};

export default function useCustomQRs() {
    const drawTextOnCanvas = (ctx, text, canvasWidth) => {
        ctx.font = "20px Arial";
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.fillText(text, canvasWidth / 2, 802);
    };
    const [creationProgress, setCreationProgress] = useState(0);
    const [zipProgress, setZipProgress] = useState(0);

    const OnDownload = async (data) => {
        if (data.length) {
            const zip = new JSZip();

            const QrTemp = new Image();
            QrTemp.src = QrTemplate;

            setCreationProgress(0);
            setZipProgress(0);

            const updateProgress = (progress, setProgress) => {
                const roundedProgress = Math.round(progress);
                setProgress((prevProgress) => (prevProgress !== roundedProgress ? roundedProgress : prevProgress));
            };

            const totalItems = data.length;

            for (let index = 0; index < totalItems; index++) {
                const item = data[index];
                const qrCode = new QRCodeStyling({ ...options, data: item.qr });

                // Wait for QR code to render
                await new Promise((resolve) => setTimeout(resolve, 1000));

                // Get the raw QR code image data URL
                const rawData = await qrCode.getRawData("png");
                const img = new Image();
                img.src = URL.createObjectURL(rawData);

                // Wrap img.onload in a Promise to ensure it completes before proceeding
                await new Promise((resolve) => {
                    img.onload = () => {
                        const canvas = document.createElement("canvas");
                        canvas.width = QrTemp.width;
                        canvas.height = QrTemp.height;

                        const ctx = canvas.getContext("2d");
                        ctx.fillStyle = "white";
                        ctx.fillRect(0, 0, canvas.width, canvas.height);

                        ctx.drawImage(QrTemp, 0, 0);
                        ctx.drawImage(img, canvas.width / 2 - img.width / 2, 325);
                        drawTextOnCanvas(ctx, item.srNo, canvas.width);

                        const filename = item.srNo;
                        let imgDataUrl = canvas.toDataURL("image/png");
                        imgDataUrl = imgDataUrl.split(",")[1];
                        zip.file(`${filename}.png`, imgDataUrl, { base64: true });
                        resolve(); // Resolve the promise
                    };
                });
                updateProgress(((index + 1) / totalItems) * 100, setCreationProgress);
                // Update the creation progress based on the number of items processed
                // setCreationProgress(((index + 1) / totalItems) * 100);
            }

            const content = await zip.generateAsync({ type: "blob" }, (metadata) => {
                // Update zip progress based on the zip library's metadata
                updateProgress(metadata.percent, setZipProgress);
            });

            const link = document.createElement("a");
            link.href = URL.createObjectURL(content);
            link.download = `${getEpoch()}.zip`;
            link.click();

            // Reset progress bars after download is complete
            setCreationProgress(0);
            setZipProgress(0);
        }
    };

    const OnDownloadPNG = async (data) => {
        try {
            await Promise.all(
                data.map(async (item) => {
                    const qrCode = new QRCodeStyling({ ...options, data: item.qr });

                    // Wait for QR code to render
                    await new Promise((resolve) => setTimeout(resolve, 1000));

                    // Get the raw QR code image data URL
                    const rawData = await qrCode.getRawData("png");

                    const img = new Image();
                    img.src = URL.createObjectURL(rawData);

                    // Wrap img.onload in a Promise to ensure it completes before proceeding
                    await new Promise((resolve) => {
                        img.onload = () => {
                            const canvas = document.createElement("canvas");
                            canvas.width = qrCode._canvas.width;
                            canvas.height = qrCode._canvas.height + 50;

                            const ctx = canvas.getContext("2d");
                            ctx.fillStyle = "white";
                            ctx.fillRect(0, 0, canvas.width, canvas.height);

                            ctx.drawImage(img, 0, 50);
                            drawTextOnCanvas(ctx, item.srNo, canvas.width);

                            const link = document.createElement("a");
                            link.href = canvas.toDataURL("image/png");
                            link.download = `${item.srNo}.png`;
                            link.click();
                            resolve(); // Resolve the promise
                        };
                    });
                })
            );
        } catch (error) {
            console.error("Error downloading QR codes:", error);
        }
    };

    return { creationProgress, zipProgress, OnDownload, OnDownloadPNG };
}
