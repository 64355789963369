import { types } from "../types/types";
const intitalState = {
    allUsers: [],
};

const userReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.CHANGE_USER:
            return {
                ...state,
                allUsers: action.payload,
            };
        default:
            return { ...state };
    }
};

export default userReducer;
