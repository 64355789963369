import React, { useState, useEffect } from "react";
import FormPage, { CustomGridLayout } from "../../shared/Layouts/FormPage";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateOrderStatus, viewShippingOrder } from "../../redux/actions/shippingOrderAction";
import { Accordion, AccordionTab } from "primereact/accordion";
import { getQRUnassigned, tagQRtoVehicle } from "../../redux/actions/qrCodeAction";
import PrimaryButton from "../../shared/Button/PrimaryButton";
import { CustomDropDown } from "../../shared/AllInput";
import { customConfirmPopup } from "../../utils/commonFunctions";
import CustomAutocomplete from "../../shared/CustomAutocomplete";
import { newOrdersCount } from "../../redux/actions/dashboardAction";

const ViewShippingOrderForm = ({ backText, backTo, heading, heading2 }) => {
    const history = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();

    const [data, setData] = useState({
        user: {},
        vehicle: {},
        shippingAddress: {},
        replacementRequest: {},
        qrId: null,
        oldQrId: null,
        qrAssigned: false,
        status: false,
    });
    const [filteredQRCodes, setFilteredQRCodes] = useState([]);

    useEffect(() => {
        dispatch(newOrdersCount());
    }, [dispatch]);

    useEffect(() => {
        if (id) {
            dispatch(
                viewShippingOrder(id, (data) => {
                    setData({
                        user: data.user,
                        qrId: data?.qrCode ? { name: data?.qrCode?.srNo, value: data?.qrCode?._id } : null,
                        oldQrId: data?.qrCode ? { name: data?.qrCode?.srNo, value: data?.qrCode?._id } : null,
                        qrAssigned: data.qrCode ? true : false,
                        vehicle: data.vehicle,
                        shippingAddress: data.shippingAddress,
                        replacementRequest: data?.replacementRequest,
                        status: data?.status,
                    });
                })
            );
        }
    }, [id, dispatch]);

    let unassignedQRCode = useSelector((state) => state.qrCode.unassignedQRCode);
    unassignedQRCode = unassignedQRCode.map((item) => ({ name: item.srNo, value: item._id }));

    useEffect(() => {
        dispatch(getQRUnassigned());
    }, [dispatch]);

    const handleChange = ({ name, value }) => {
        setData((prev) => ({ ...prev, [name]: value }));
        if (name === "status") {
            dispatch(updateOrderStatus(id, value));
        }
    };

    const onAssign = () => {
        customConfirmPopup(
            () => dispatch(tagQRtoVehicle(data?.qrId && data?.qrId?.value ? data?.qrId?.value : data?.qrId, data.vehicle._id, () => history(backTo))),
            "Are you sure?",
            "center",
            "Assign and Dispatch"
        );
    };

    const searchQR = (event) => {
        let query = event.query.toLowerCase();
        let filtered = unassignedQRCode.filter((qr) => qr.name.toLowerCase().includes(query));
        setFilteredQRCodes(filtered);
    };

    let statusOptions = [
        { name: "Placed", value: "PLACED" },
        { name: "Dispatched", value: "DISPATCHED" },
    ];

    return (
        <FormPage>
            <CustomGridLayout>
                <div className="col-12 md:col-5">
                    <div className="card">
                        <div className="flex align-items-center justify-content-between">
                            <h3>{heading}</h3>
                        </div>
                        <hr />
                        <div className="grid">
                            <p className="col-12">
                                Name: {data.user?.firstName} {data.user?.lastName}
                            </p>
                            <p className="col-12">
                                Email: <a href={`mailto:${data?.user?.email}`}> {data?.user?.email}</a>
                            </p>
                            <p className="col-12">
                                Mobile: <a href={`tel:${data?.user?.mobile}`}> {data?.user?.mobile}</a>
                            </p>
                            <p className="col-12">
                                Address: {data?.user?.address} {data?.user?.city} {data?.user?.state} {data?.user?.pincode}
                            </p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="flex align-items-center justify-content-between">
                            <h3>{heading2}</h3>
                        </div>
                        <hr />
                        <div className="grid">
                            <p className="col-12">
                                Name: {data.shippingAddress?.firstName} {data.shippingAddress?.lastName}
                            </p>
                            <p className="col-12">
                                Address: {data?.shippingAddress?.address} {data?.shippingAddress?.city} {data?.shippingAddress?.state}{" "}
                                {data?.shippingAddress?.pinCode}
                            </p>
                        </div>
                    </div>
                    {data?.replacementRequest ? (
                        <div className="card">
                            <div className="flex align-items-center justify-content-between">
                                <h3>Replacement Information</h3>
                            </div>
                            <hr />
                            <div className="grid">
                                <p className="col-12">Reason: {data?.replacementRequest?.reason}</p>
                                <p className="col-12">Comments: {data?.replacementRequest?.comments}</p>
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="col-12 md:col-7 mx-auto ">
                    <div className="card">
                        <div className="grid">
                            <p className="col-12 ml-2">Status : <strong>{data?.status}</strong></p>
                        </div>
                        <div className="flex col-12">
                            <CustomAutocomplete
                                col={12}
                                name="qrId"
                                label="QR Sr. No."
                                field="name"
                                suggestions={filteredQRCodes}
                                completeMethod={searchQR}
                                data={data}
                                onChange={handleChange}
                                // forceSelection={true}
                                dropdown={true}
                            />
                        </div>
                        <small className="ml-2 mb-3"><strong>Old QR Assigned :</strong> {data.oldQrId && data.oldQrId.name ? data.oldQrId.name : "N/a"}</small>
                        <PrimaryButton extraClassName="col-3" label="Assign" onClick={onAssign} disabled={!data?.qrId} />
                        <br />
                        {data.vehicle && (
                            <Accordion multiple className="" activeIndex={0}>
                                <AccordionTab header={`Vehicle Details`}>
                                    <p>Vehicle Model: {data.vehicle.vehicleModel?.name}</p>
                                    <p>Vehicle Name: {data.vehicle.vehicleBrand?.name}</p>
                                    <p>Vehicle Number: {data.vehicle.vehicleNumber}</p>
                                </AccordionTab>
                            </Accordion>
                        )}
                    </div>
                </div>
            </CustomGridLayout>
        </FormPage>
    );
};

export default ViewShippingOrderForm;
