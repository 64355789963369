import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";
import { ProtectedRoutes } from "./routes/ProtectedRoutes";

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import { ConfirmPopup } from "primereact/confirmpopup";
import { ConfirmDialog } from "primereact/confirmdialog";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import { isAuthenticated } from "./services/auth";
import Loader from "./shared/Loader";
import { useDispatch, useSelector } from "react-redux";
import { newOrdersCount } from "./redux/actions/dashboardAction";

const App = () => {
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const dispatch = useDispatch();

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };
    const count = useSelector((state) => state.dashboard.newOrdersCount);
    const menu = [
        {
            label: "Home",
            items: [
                {
                    label: "Dashboard",
                    icon: "pi pi-fw pi-home",
                    to: "/dashboard",
                },
                { label: "Users", icon: "pi pi-fw pi-users", to: "/user" },
                { label: "Call Logs", icon: "pi pi-fw pi-phone", to: "/call-logs" },
                { label: "Expired Memberships", icon: "pi pi-times-circle", to: "/expired-memberships" },
                { label: "Executives", icon: "pi pi-fw pi-users", to: "/executive" },
                { label: "Executive Payroll", icon: " pi pi-book", to: "/executive-payroll" },
                { label: "Vehicle Brands", icon: "pi pi-fw pi-car", to: "/vehicle-brand" },
                { label: "Vehicle Models", icon: "pi pi-fw pi-car", to: "/vehicle-model" },
                { label: "QR Code Generator", icon: "pi pi-qrcode", to: "/qr-code" },
                {
                    label: "Shipping Order",
                    icon: "pi pi-shopping-bag",
                    to: "/shipping-order",
                    ...(count?.shippingOrderCount && { tag: count.shippingOrderCount }),
                },
                // { label: "Reports", icon: "pi pi-fw pi-chart-bar", to: "/reports" },
                {
                    label: "Replacement",
                    icon: "pi pi-briefcase",
                    to: "/replacement",
                    ...(count?.replacementOrderCount && { tag: count.replacementOrderCount }),
                },
                {
                    label: "Support Query",
                    icon: "pi pi-envelope",
                    to: "/support-query",
                    ...(count?.supportQueryCount && { tag: count.supportQueryCount }),
                },
                { label: "Static Pages", icon: "pi pi-file", to: "/static-page" },
            ],
        },
    ];

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });
    const [auth, setAuth] = useState(false);

    useEffect(() => {
        if (isAuthenticated()) {
            setAuth(true);
        } else {
            setAuth(false);
        }
    }, [isAuthenticated()]);

    useEffect(() => {
        if (isAuthenticated()) {
        }
        dispatch(newOrdersCount());
    }, [isAuthenticated()]);

    return (
        auth && (
            <>
                <ConfirmPopup />
                <ConfirmDialog />
                <Loader />
                <div className={wrapperClass} onClick={onWrapperClick}>
                    <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
                    <AppTopbar
                        onToggleMenuClick={onToggleMenuClick}
                        layoutColorMode={layoutColorMode}
                        mobileTopbarMenuActive={mobileTopbarMenuActive}
                        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
                    />

                    <div className="layout-sidebar" onClick={onSidebarClick}>
                        <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                    </div>

                    <div className="layout-main-container">
                        <div className="layout-main">
                            <ProtectedRoutes />
                        </div>

                        <AppFooter layoutColorMode={layoutColorMode} />
                    </div>

                    <AppConfig
                        rippleEffect={ripple}
                        onRippleEffect={onRipple}
                        inputStyle={inputStyle}
                        onInputStyleChange={onInputStyleChange}
                        layoutMode={layoutMode}
                        onLayoutModeChange={onLayoutModeChange}
                        layoutColorMode={layoutColorMode}
                        onColorModeChange={onColorModeChange}
                    />

                    <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                        <div className="layout-mask p-component-overlay"></div>
                    </CSSTransition>
                </div>
            </>
        )
    );
};

export default App;
