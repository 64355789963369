import React, { useEffect, useState, useRef } from "react";
import FormPage, { CustomGridLayout } from "../../shared/Layouts/FormPage";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getQRCode } from "../../redux/actions/qrCodeAction";
import UseCustomQR from "./UseCustomQr";

const ViewQrCode = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [data, setData] = useState({
        qrData: "",
    });
    useEffect(() => {
        dispatch(
            getQRCode(id, (data) => {
                setData(data);
            })
        );
    }, [dispatch]);

    return (
        <FormPage>
            <CustomGridLayout>
                <div className="col-12 md:col-12">
                    <div className="card">
                        <h3>QR Code</h3>
                        {/* <h5>{data.srNo}</h5> */}
                        <div className="flex align-items-center">
                            <UseCustomQR payload={data} />
                        </div>
                    </div>
                </div>
            </CustomGridLayout>
        </FormPage>
    );
};

export default ViewQrCode;
