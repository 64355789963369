import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CallLogsTable from "./CallLogsTable";
import { confirmDelete, customConfirmPopup } from "../../utils/commonFunctions";
import { getCallLogs } from "../../redux/actions/callLogsAction";

const CallLogs = () => {
    const history = useNavigate();
    const dispatch = useDispatch();

    let allCallLogs = useSelector((state) => state.callLogs.allCallLogs);
    const totalRecords = allCallLogs?.totalRecords || 0;

    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState("");

    useEffect(() => {
        dispatch(getCallLogs(page, limit, search));
    }, [dispatch, page, limit, search]);

    const getFilteredData = (data, search) => {
        if (search.length) {
            data = data?.filter(
                (item) =>
                    item?.caller?.mobile?.toLowerCase().includes(search.toLowerCase()) ||
                    item?.caller?.email?.toLowerCase().includes(search.toLowerCase()) ||
                    item?.reciever?.mobile?.toLowerCase().includes(search.toLowerCase()) ||
                    item?.reciever?.email?.toLowerCase().includes(search.toLowerCase())
            );
        }
        return data;
    };

    allCallLogs = getFilteredData(allCallLogs?.data, search);
    allCallLogs = allCallLogs?.length > 0 && allCallLogs?.map((item) => ({
        ...item,
        time: new Date(item.time),
    }));

    return <CallLogsTable heading="All Call Logs" allCallLogs={allCallLogs} loading={loading} history={history} search={search} setSearch={setSearch} page={page} limit={limit} totalRecords={totalRecords} setPage={setPage} setLimit={setLimit} />;
};

export default CallLogs;
