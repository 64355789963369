import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constants";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";
import { uploadImages } from "./uploadFiles";
import moment from "moment";

const getExecutives = (setLoading) => async (dispatch) => {
    if (setLoading) {
        setLoading(true);
    }
    const res = await api("get", Constants.END_POINT.EXECUTIVE);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CHANGE_EXECUTIVE,
                payload: res.data,
            });
        }
    }
    if (setLoading) {
        setLoading(false);
    }
};
const getExecutive =
    (id, page, limit, search = "", selectedStatus, returnData) =>
    async (dispatch) => {
        dispatch(showLoaderAction());
        const res = await api("get", `${Constants.END_POINT.EXECUTIVE}${id}?page=${page}&limit=${limit}&search=${search}&status=${selectedStatus}`);
        if (res.success) {
            if (res.data) {
                if (returnData) {
                    returnData(res.data);
                }
            }
        }
        dispatch(hideLoaderAction());
    };
const getExecutiveEarnings =
    (id, page, limit, search = "", selectedStatus, returnData) =>
    async (dispatch) => {
        dispatch(showLoaderAction());
        const res = await api(
            "get",
            `${Constants.END_POINT.EXECUTIVE_EARNINGS}${id}?page=${page}&limit=${limit}&search=${search}&status=${selectedStatus}`
        );
        if (res.success) {
            if (res.data) {
                if (returnData) {
                    returnData(res.data);
                }
            }
        }
        dispatch(hideLoaderAction());
    };
const addExecutive = (data, next, setLoading) => async (dispatch) => {
    dispatch(showLoaderAction());
    setLoading(true);

    if (data.image && data.image.length) {
        let urls = await uploadImages(data.image);
        data.image = urls[0];
    } else {
        data.image = "";
    }

    if (data.documents && data.documents.length) {
        let urls = await uploadImages(data.documents);
        data.documents = urls;
    } else {
        data.documents = [];
    }

    data.isBlocked = data?.isBlocked?.key;

    const res = await api("post", Constants.END_POINT.EXECUTIVE, data);
    if (res.success) {
        dispatch(showToast({ severity: "success", summary: res.message }));
        next(res.data);
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    setLoading(false);
    dispatch(hideLoaderAction());
};

const editExecutive = (id, data, dispatch1, history, setLoading) => async (dispatch) => {
    dispatch(showLoaderAction());
    setLoading(true);

    if (data?.image?.length) {
        let urls = await uploadImages(data.image, dispatch1);
        data.image = urls[0];
    } else {
        data.image = "";
    }

    if (data.documents && data.documents.length) {
        let urls = await uploadImages(data.documents);
        data.documents = urls;
    } else {
        data.documents = [];
    }

    data.isBlocked = data?.isBlocked?.key;

    const res = await api("put", Constants.END_POINT.EXECUTIVE + id, data);
    if (res.success) {
        dispatch(showToast({ severity: "success", summary: res.message }));
        history(-1);
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    setLoading(false);
    dispatch(hideLoaderAction());
};

const getAllExecutivePayroll = (page, limit, dateRange) => async (dispatch) => {
    console.log("dateRange>>", dateRange);
    dispatch(showLoaderAction());

    const res = await api(
        "get",
        `${Constants.END_POINT.EXECUTIVE_PAYROLL}`,
        {},
        {
            page,
            limit,
            ...(dateRange?.length && {
                ...(dateRange?.[0] && { startDate: moment(dateRange[0]).format("MM-DD-YYYY") }),
                ...(dateRange?.[1] && { endDate: moment(dateRange[1]).format("MM-DD-YYYY") }),
            }),
        }
    );
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.EXECUTIVE_PAYROLL,
                payload: res.data,
            });
        }
    }
    dispatch(hideLoaderAction());
};

const disableExecutive = (id, isBlocked) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("delete", Constants.END_POINT.EXECUTIVE_DISABLE + id, { isBlocked });
    if (res.success) {
        // dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const deleteExecutive = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("delete", Constants.END_POINT.EXECUTIVE + id);
    if (res.success) {
        dispatch(getExecutives());
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

export { getExecutives, getExecutive, addExecutive, editExecutive, deleteExecutive, disableExecutive, getExecutiveEarnings, getAllExecutivePayroll };
