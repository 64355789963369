import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSupportQueries } from "../../redux/actions/supportQueriesAction";
import SupportQueryTable from "./SupportQueryTable";

const SupportQuery = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getSupportQueries(setLoading));
    }, [dispatch]);

    let allSupportQueries = useSelector((state) => state.supportQueries.allSupportQueries);

    const [loading, setLoading] = useState(false);

    const [search, setSearch] = useState("");

    const getFilteredData = (data, search) => {
        if (search.length) {
            data = data?.filter(
                (item) =>
                    // item?.firstName?.includes(search) ||
                    // item?.lastName?.includes(search) ||
                    // item?.mobile?.includes(search) ||
                    item?.email?.includes(search)
            );
        }
        return data;
    };

    allSupportQueries = getFilteredData(allSupportQueries, search);
    allSupportQueries = allSupportQueries.map((item) => ({
        ...item,
        time: new Date(item.time),
    }));

    return (
        <SupportQueryTable
            heading="Support Queries"
            allSupportQueries={allSupportQueries}
            loading={loading}
            history={history}
            search={search}
            setSearch={setSearch}
        />
    );
};

export default SupportQuery;
