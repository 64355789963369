import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constants";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";

const getQRList = (page, limit, search, selectedStatus) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", `${Constants.END_POINT.QR_CODE}?page=${page}&limit=${limit}&search=${search}&status=${selectedStatus}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CHANGE_QR_CODE,
                payload: res.data,
            });
        }
    }
    dispatch(hideLoaderAction());
};
const getQRCode = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.QR_CODE + id);
    if (res.success) {
        if (res.data) {
            if (returnData) {
                returnData(res.data);
            }
        }
    }
    dispatch(hideLoaderAction());
};

const addQrCodeAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("post", Constants.END_POINT.QR_CODE, data);
    if (res.success) {
        dispatch(showToast({ severity: "success", summary: res.message }));
        next(res.data);
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};
const UserEnableDisableAction = (id, data) => async (dispatch) => {
    const res = await api("delete", Constants.END_POINT.QR_CODE + id, { disableStatus: data });
    if (res.success) {
        dispatch(getQRList());
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

const getQRUnassigned = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.UNASSIGNED_QR_CODE);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CHANGE_UNASSIGNED_QR_CODE,
                payload: res.data,
            });
        }
    }
    dispatch(hideLoaderAction());
};

const tagQRtoVehicle = (id, vehicle, next) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("put", Constants.END_POINT.TAG_QR_CODE + id, { vehicle });
    if (res.success) {
        dispatch(getQRUnassigned());
        next();
    }
    dispatch(hideLoaderAction());
};

const assignQRtoExecutive =
    (executiveId, qrCodes = [], next) =>
    async (dispatch) => {
        dispatch(showLoaderAction());

        const res = await api("post", Constants.END_POINT.ASSIGN_QR_CODE, { assignedTo: executiveId, qrCodes });
        if (res.success) {
            next();
        }
        dispatch(hideLoaderAction());
    };

export { getQRList, getQRCode, UserEnableDisableAction, addQrCodeAction, getQRUnassigned, tagQRtoVehicle, assignQRtoExecutive };
