import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Gallery from "../../assets/images/gallery.png";
import InputLayout from "./InputLayout";
import { getImageURL } from "../../utils/imageUrl.js";
import { showToast } from "../../redux/actions/toastAction";
import { Tag } from 'primereact/tag';

export default function CustomFileInput({
    label,
    name,
    onFilesChange,
    errorMessage,
    extraClassName,
    value,
    data,
    limit,
    multiple,
    col,
    required,
    removeable,
    editable,
    accept = "image/*",
    ...props
}) {
    const [files, setFiles] = useState(value || data?.[name] || []);
    const dispatch = useDispatch();

    useEffect(() => {
        if (value || data?.[name]) {
            if (JSON.stringify(value || data?.[name]) !== JSON.stringify(files)) {
                setFiles(value || data?.[name]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value || data?.[name]]);

    useEffect(() => {
        if (onFilesChange) {
            if (files.length) {
                onFilesChange({ name, value: files });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files, name]);

    const onDelete = (id) => {
        if (editable) {
            const s = files.filter((item, index) => index !== id);
            setFiles([...s]);
        }
    };

    const onFileChange = (e) => {
        if (editable) {
            const chosenFiles = e.target.files;
            handleFileChange(Object.values(chosenFiles));
        }
    };

    const handleFileChange = (uploadedFiles) => {
        let newFiles = [];
        uploadedFiles.forEach((file) => {
            if (files.findIndex((f) => f.name === file.name) === -1) {
                newFiles.push(file);
            }
        });
        if (multiple) {
            if (limit) {
                let newArr = [...files, ...newFiles];
                if (newArr.length > limit) {
                    newArr = newArr.slice(0, limit);
                    dispatch(
                        showToast({
                            severity: "warn",
                            summary: "Limit Exceeded",
                            detail: `Max. file limit is ${limit}.`,
                        })
                    );
                }
                setFiles(newArr);
            } else {
                setFiles([...files, ...newFiles]);
            }
        } else {
            setFiles([...newFiles]);
        }
    };

    const isFileObject = (file) => {
        return file instanceof File;
    };

    const getFileName = (file) => {
        return isFileObject(file) ? file.name : file.split('/').pop();
    };

    return (
        <>
            <input
                name={name}
                onChange={onFileChange}
                id={name}
                type="file"
                accept={accept}
                hidden
                {...props}
                multiple={multiple}
                disabled={!editable}
            />
            <InputLayout
                col={col || 12}
                label={label || "Upload Photo"}
                name={name}
                required={required}
                extraClassName={extraClassName}
                data={data}
                errorMessage={errorMessage}
            >
                <div className="image-box">
                    {files && files.length ? (
                        <div className="md:col-12 grid">
                            {files.map((file, i) => (
                                <div
                                    key={i}
                                    className="mr-2 my-1 photoDiv"
                                    style={{
                                        height: "10px",
                                        width: "150px",
                                    }}
                                >
                                    <label htmlFor={name}>
                                        {getFileName(file).length > 15 ? `${getFileName(file).substring(0, 15)}...` : getFileName(file)}
                                    </label>
                                    {" "}
                                    {removeable && <i onClick={() => onDelete(i)} className="pi pi-times" style={{ color: 'red' }}></i>}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div
                            className="photo-upload flex justify-content-center align-items-center"
                            style={{
                                height: "25px",
                                width: "100px",
                            }}
                        >
                            <label className="mr-2 my-1 photoDiv" htmlFor={name}>
                                <Tag value="Add File(s)"></Tag>
                            </label>
                        </div>
                    )}
                </div>
            </InputLayout>
        </>
    );
}
