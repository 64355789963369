import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import QRCodeTable from "./QRCodeTable";
import { getQRList } from "../../redux/actions/qrCodeAction";

const QRCode = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const getQueryParams = () => {
        const params = new URLSearchParams(location.search);
        return {
            page: parseInt(params.get('page') || '1', 10),
            limit: parseInt(params.get('limit') || '10', 10),
            search: params.get('search') || '',
            status: params.get('status') || ''
        };
    };

    const { page: initialPage, limit: initialLimit, search: initialSearch, status: initialStatus } = getQueryParams();

    const [page, setPage] = useState(initialPage);
    const [limit, setLimit] = useState(initialLimit);
    const [search, setSearch] = useState(initialSearch);
    const [selectedStatus, setSelectedStatus] = useState(initialStatus);

    useEffect(() => {
        const queryParams = new URLSearchParams();
        if (page) queryParams.set('page', page);
        if (limit) queryParams.set('limit', limit);
        if (search) queryParams.set('search', search);
        if (selectedStatus) queryParams.set('status', selectedStatus);
        navigate({ search: queryParams.toString() });
    }, [page, limit, search, selectedStatus, navigate]);

    useEffect(() => {
        dispatch(getQRList(page, limit, search, selectedStatus));
    }, [dispatch, page, limit, search, selectedStatus]);

    let allQRCode = useSelector((state) => state.qrCode.allQRCode);
    const loading = useSelector((state) => state?.loader?.isLoading);
    const totalRecords = allQRCode?.totalRecords || 0;

    allQRCode = allQRCode?.data?.map((item) => ({
        ...item,
        time: new Date(item.time),
    }));

    return (
        <QRCodeTable
            heading="All QRCode"
            allQRCode={allQRCode}
            loading={loading}
            history={navigate}
            search={search}
            setSearch={setSearch}
            page={page}
            limit={limit}
            totalRecords={totalRecords}
            setPage={setPage}
            setLimit={setLimit}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
        />
    );
};

export default QRCode;
