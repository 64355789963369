import React, { useEffect } from 'react';
import FormPage from '../../../shared/Layouts/FormPage';
import StaticPageContainer from './StaticPageContainer';
import PrimaryButton from '../../../shared/Button/PrimaryButton';
import { CustomEditor, CustomForm, CustomInput } from '../../../shared/AllInput';
import ReactQuill from 'react-quill';
// import { Editor } from 'primereact/editor';

function StaticPageForm({ type }) {
    const { data, handleChange, loading, onSubmit, setFormType } = StaticPageContainer();

    useEffect(() => {
        setFormType(type);
    }, [type, setFormType]);

    return (
        <>
            <FormPage>
                <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
                    <div className="col-12 md:col-6">
                        <div className="card">
                            <div className="flex align-items-center justify-content-between">
                                <h3>Static Page Information</h3>
                            </div>
                            <hr />
                            <div className="grid">
                                <CustomInput col={12} label="Title" name="title" required data={data} onChange={handleChange} />
                                <CustomEditor col={12} label="Content" name="content" required data={data} onChange={handleChange} value={data.content} />
                                {type !== "VIEW" &&
                                    <div className='my-2'><PrimaryButton label="Save" loading={loading} onClick={onSubmit} col={6} /></div>}
                            </div>
                        </div>
                    </div>
                </CustomForm>
            </FormPage>
        </>
    );
}

export default StaticPageForm;
