import Dashboard from "../views/Dashboard";
import Profile from "../views/Profile";
import Users from "../views/User/Users";
import ViewUser from "../views/User/ViewUser";
import Replacement from "../views/Replacement/Replacement";
import ViewReplacement from "../views/Replacement/ViewReplacement";
import ShippingOrder from "../views/ShippingOrder/ShippingOrder";
import ViewShippingOrder from "../views/ShippingOrder/ViewShippingOrder";
import QRCode from "../views/QRCode/QRCode";
import ViewQrCode from "../views/QRCode/ViewQrCode";
import SupportQuery from "../views/SupportQuery/SupportQuery";
import ViewSupportQuery from "../views/SupportQuery/ViewSupportQuery";
import Executive from "../views/Executive/Executive";
import ViewExecutive from "../views/Executive/ExecutiveForm/ViewExecutive";
import EditExecutive from "../views/Executive/ExecutiveForm/EditExecutive";
import AddExecutive from "../views/Executive/ExecutiveForm/AddExecutive";
import VehicleBrand from "../views/VehicleBrand/VehicleBrand";
import AddVehicleBrand from "../views/VehicleBrand/VehicleBrandForm/AddVehicleBrand";
import ViewVehicleBrand from "../views/VehicleBrand/VehicleBrandForm/ViewVehicleBrand";
import ViewVehicleModel from "../views/VehicleModel/VehicleModelForm/ViewVehicleModel";
import AddVehicleModel from "../views/VehicleModel/VehicleModelForm/AddVehicleModel";
import EditVehicleModel from "../views/VehicleModel/VehicleModelForm/EditVehicleModel";
import VehicleModel from "../views/VehicleModel/VehicleModel";
import EditVehicleBrand from "../views/VehicleBrand/VehicleBrandForm/EditVehicleBrand";
import StaticPage from "../views/StaticPage/StaticPage";
import AddStaticPage from "../views/StaticPage/StaticPageForm/AddStaticPage";
import ViewStaticPage from "../views/StaticPage/StaticPageForm/ViewStaticPage";
import EditStaticPage from "../views/StaticPage/StaticPageForm/EditStaticPage";
import CallLogs from "../views/CallLogs/CallLogs";
import ViewCallLogs from "../views/CallLogs/CallLogsForm/ViewCallLogs";
import ExpiredMembershipUsers from "../views/ExpiredMembershipUsers/ExpiredMembershipUsers";
import { elements } from "chart.js/auto";
import ViewEarnings from "../views/Executive/ExecutiveForm/ViewEarnings";

export const PrivateRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        element: <Dashboard />,
    },
    {
        path: "/user",
        name: "User",
        element: <Users />,
    },
    {
        path: "user/view/:id",
        name: "View",
        element: <ViewUser />,
    },
    {
        path: "/profile",
        name: "Profile",
        element: <Profile />,
    },
    {
        path: "/replacement",
        name: "Replacement",
        element: <Replacement />,
    },
    {
        path: "replacement/view/:id",
        name: "View",
        element: <ViewReplacement />,
    },
    {
        path: "/shipping-order",
        name: "Shipping Order",
        element: <ShippingOrder />,
    },
    {
        path: "shipping-order/:id",
        name: "View",
        element: <ViewShippingOrder />,
    },
    {
        path: "/qr-code",
        name: "QR-Code",
        element: <QRCode />,
    },
    {
        path: "qr-code/view/:id",
        name: "View",
        element: <ViewQrCode />,
    },
    {
        path: "/support-query",
        name: "List",
        element: <SupportQuery />,
    },
    {
        path: "support-query/:id",
        name: "View",
        element: <ViewSupportQuery />,
    },
    {
        path: "/executive",
        name: "List",
        element: <Executive />,
    },
    {
        path: "/executive-payroll",
        name: "Executive Payroll",
        element: <ViewEarnings />,
    },
    {
        path: "executive/view/:id",
        name: "View",
        element: <ViewExecutive />,
    },
    {
        path: "/executive/view-earnings/:id",
        name: "View Earnings",
        element: <ViewEarnings />,
    },
    {
        path: "executive/add",
        name: "View",
        element: <AddExecutive />,
    },
    {
        path: "executive/edit/:id",
        name: "View",
        element: <EditExecutive />,
    },
    {
        path: "vehicle-brand",
        name: "View",
        element: <VehicleBrand />,
    },
    {
        path: "vehicle-brand/add",
        name: "Add",
        element: <AddVehicleBrand />,
    },
    {
        path: "vehicle-brand/view/:id",
        name: "View",
        element: <ViewVehicleBrand />,
    },
    {
        path: "vehicle-brand/edit/:id",
        name: "Edit",
        element: <EditVehicleBrand />,
    },
    {
        path: "vehicle-model",
        name: "View",
        element: <VehicleModel />,
    },
    {
        path: "vehicle-model/add",
        name: "Add",
        element: <AddVehicleModel />,
    },
    {
        path: "vehicle-model/view/:id",
        name: "View",
        element: <ViewVehicleModel />,
    },
    {
        path: "vehicle-model/edit/:id",
        name: "View",
        element: <EditVehicleModel />,
    },
    {
        path: "static-page",
        name: "View",
        element: <StaticPage />,
    },
    // {
    //     path: "static-page/add",
    //     name: "Add",
    //     element: <AddStaticPage />,
    // },
    {
        path: "static-page/view/:id",
        name: "View",
        element: <ViewStaticPage />,
    },
    {
        path: "static-page/edit/:id",
        name: "View",
        element: <EditStaticPage />,
    },
    {
        path: "call-logs",
        name: "View",
        element: <CallLogs />,
    },
    {
        path: "expired-memberships",
        name: "View",
        element: <ExpiredMembershipUsers />,
    },
];
