import { types } from "../types/types";
const intitalState = {
    allExecutives: [],
    allExecutivePayroll: [],
};

const executiveReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.CHANGE_EXECUTIVE:
            return {
                ...state,
                allExecutives: action.payload,
            };

        case types.EXECUTIVE_PAYROLL: {
            return {
                ...state,
                allExecutivePayroll: action.payload,
            };
        }
        default:
            return { ...state };
    }
};

export default executiveReducer;
