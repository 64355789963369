import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constants";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";

const getExpiredMemberships = (page, limit, search) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", `${Constants.END_POINT.EXPIRED_MEMBERSHIPS}?page=${page}&limit=${limit}&search=${search}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.EXPIRED_MEMBERSHIPS,
                payload: res.data,
            });
        }
    }
    dispatch(hideLoaderAction());
};

export { getExpiredMemberships };
