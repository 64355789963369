import { types } from "../types/types";
const intitalState = {
    allQRCode: [],
    unassignedQRCode: [],
};

const qrCodeReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.CHANGE_QR_CODE:
            return {
                ...state,
                allQRCode: action.payload,
            };
        case types.CHANGE_UNASSIGNED_QR_CODE:
            return {
                ...state,
                unassignedQRCode: action.payload,
            };
        default:
            return { ...state };
    }
};

export default qrCodeReducer;
