import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getShippingOrder } from "../../redux/actions/shippingOrderAction";
import ShippingTable from "./ShippingOrderTable";

const ShippingOrder = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getShippingOrder(setLoading));
    }, [dispatch]);

    let allShippingOrder = useSelector((state) => state.shippingOrder.allShippingOrder);

    const [loading, setLoading] = useState(false);

    const [search, setSearch] = useState("");
    const getFilteredData = (data, search) => {
        if (search.length) {
            data = data?.filter(
                (item) =>
                    item?.firstName?.includes(search) ||
                    item?.lastName?.includes(search) ||
                    item?.mobile?.includes(search) ||
                    item?.email?.includes(search)
            );
        }
        return data;
    };

    allShippingOrder = getFilteredData(allShippingOrder, search);
    allShippingOrder = allShippingOrder.map((item) => ({
        ...item,
        time: new Date(item.time),
    }));

    return (
        <ShippingTable
            heading="Shipping Orders"
            allShippingOrder={allShippingOrder}
            loading={loading}
            history={history}
            search={search}
            setSearch={setSearch}
        />
    );
};

export default ShippingOrder;
