import React from "react";

export default function InputLayout({ label, name, required, col, extraClassName, errorMessage, data, children, maxLength }) {
    return (
        <>
            <div className={`px-1 mb-3 col-${col} ${extraClassName}`}>
                <div className="flex flex-column gap-2">
                    <div className="flex justify-content-between">
                        <label htmlFor={name}>
                            {label}
                            {required ? <span className="p-error">*</span> : ""}
                        </label>

                        {maxLength && (
                            <div>
                                Maxlength: {maxLength - data?.[name].length}/{maxLength}
                            </div>
                        )}
                    </div>
                    {children}
                    {errorMessage || data?.formErrors?.[name] ? <small className="p-error">{errorMessage || data?.formErrors?.[name]}</small> : null}
                </div>
            </div>
        </>
    );
}
