export default class Constants {
    static END_POINT = {
        LOGIN: "/login",
        PROFILE: "/profile",
        FORGET_PASSWORD: "/forget-password",
        UPDATE_PROFILE: "/update",
        RESET_PASSWORD: "/change-forget-password",
        UPLOAD_FILES: "/upload/file",
        CHANGE_PASSWORD: "/change-password",
        UPLOAD_FILE: "/upload/file",

        //Dashborad

        GET_USERS_COUNT: "/user/count",
        NOTIFICATION: "/notification",
        CHANGE_APP_DESC: "/constant",

        //USER

        USER: "/users/",
        USER_DISABLE: "/users-disable/",
        SHIPPING_ORDER: "/order/",
        REPLACEMENT: "/replacement-request/",
        QR_CODE: "/qr-code/",
        UNASSIGNED_QR_CODE: "/qr-code-unassigned/",
        TAG_QR_CODE: "/qr-code/vehicle-tagging/",
        ASSIGN_QR_CODE: "/qr-code/assign/",
        EXPIRED_MEMBERSHIPS: "/users-expired-memberships",

        // EXECUTIVE
        EXECUTIVE: "/executive/",
        EXECUTIVE_DISABLE: "/executive-disable/",
        EXECUTIVE_EARNINGS: "/executive-revenue/",
        EXECUTIVE_PAYROLL: "/executive-payroll/",

        // SUPPORT QUERIES
        SUPPORT_QUERIES: "/support/",

        // VEHICLE BRANDS
        VEHICLE_BRANDS: "/vehicle-brand/",

        // VEHICLE MODELS
        VEHICLE_MODELS: "/vehicle-model/",

        // STATIC PAGES
        STATIC_PAGES: "/static-page/",

        // DASHBOARD
        DASHBOARD: "/dashboard/",

        // CALL_LOGS
        CALL_LOGS: "/call-logs/",

        //New Orders

        NEW_ORDERS_COUNT: "/new-orders-count",
        IMPORT_VEHICLE_BRAND: "/vehicle-brand-bulk/",
        IMPORT_VEHICLE_MODAL: "/vehicle-model-bulk/",
    };
}
