import React, { useRef } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { confirmPopup } from "primereact/confirmpopup";
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from "./services/auth";
import logoNew from "./assets/images/logoNew.png";

export const AppTopbar = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const menu = useRef(null);
    const confirm = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: "Are you sure you want to Logout?",
            icon: "pi pi-exclamation-triangle",
            accept,
            reject,
        });
    };
    const accept = () => {
        logout(() => {
            setTimeout(() => {
                navigate("/");
            }, 400);
        });
    };
    const reject = () => {};

    const navigateToPage = (path) => {
        navigate(path);
    };
    const overlayMenuItems = [
        {
            label: "Profile",
            icon: "pi pi-fw pi-user",
            command: () => {
                navigateToPage("/profile");
            },
        },
        // {
        //     label: "Change Password",
        //     icon: "pi pi-fw pi-lock",
        //     command: () => {
        //         navigateToPage("change-password");
        //     },
        // },
    ];
    return (
        <div className="layout-topbar">
            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>
            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>
            <Link to="/" className="layout-topbar-logo ml-3">
                <img src={logoNew} alt="logo" />
            </Link>
            {/* <Link to="/" className="layout-topbar-logo ml-3">
                <img src={logo} alt="logo" />
                <span>
                    {" "}
                    SCAN<span style={{ color: "#ffdb4d" }}>ME</span>
                </span>
            </Link> */}

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
                {/* <li>
                    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                        <i className="pi pi-calendar" />
                        <span>Events</span>
                    </button>
                </li> */}
                {/* <li>
                    <button
                        className="p-link layout-topbar-button"
                        onClick={() => navigateToPage("/profile")}
                    >
                        <i className="pi pi-cog" />
                        <span>Settings</span>
                    </button>
                </li> */}
                <li>
                    <button className="p-link layout-topbar-button" onClick={confirm}>
                        <i className="pi pi-power-off" />
                        <span>Logout</span>
                    </button>
                </li>
            </ul>
        </div>
    );
};
