import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constants";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";

const getDashboard = (year, setLoading) => async (dispatch) => {
    dispatch(showLoaderAction());
    if (setLoading) {
        setLoading(true);
    }
    const res = await api("get", `${Constants.END_POINT.DASHBOARD}?year=${year}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.DASHBOARD,
                payload: res.data,
            });
        }
    }
    if (setLoading) {
        setLoading(false);
    }
    dispatch(hideLoaderAction());
};
const newOrdersCount = () => async (dispatch) => {
    const res = await api("get", Constants.END_POINT.NEW_ORDERS_COUNT);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.NEW_ORDERS_COUNT,
                payload: res.data,
            });
        }
    }
};

export { getDashboard, newOrdersCount };
