import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StaticPageTable from "./StaticPageTable";
import { getStaticPages, deleteStaticPage } from "../../redux/actions/staticPageAction";
import { confirmDelete, customConfirmPopup } from "../../utils/commonFunctions";

const StaticPage = () => {
    const history = useNavigate();
    const dispatch = useDispatch();

    let allStaticPages = useSelector((state) => state.staticPage.allStaticPages);

    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");

    useEffect(() => {
        dispatch(getStaticPages(setLoading));
    }, [dispatch]);

    const getFilteredData = (data, search) => {
        if (search.length) {
            data = data?.filter((item) => item?.name?.toLowerCase().includes(search.toLowerCase()));
        }
        return data;
    };

    allStaticPages = getFilteredData(allStaticPages, search);
    allStaticPages = allStaticPages?.map((item) => ({
        ...item,
        time: new Date(item.time),
    }));

    const onDelete = (id) => {
        customConfirmPopup(() => dispatch(deleteStaticPage(id)), "Do you want to delete this record?");
    };

    return (
        <StaticPageTable
            heading="All Static Pages"
            allStaticPages={allStaticPages}
            loading={loading}
            history={history}
            search={search}
            setSearch={setSearch}
            onDelete={onDelete}
        />
    );
};

export default StaticPage;
