import { HOST } from "../constants";
import Gallery from "../assets/images/gallery.png";

export const getImageURL = (path) => {
    if (path) {
        if (typeof path === "object") {
            return URL.createObjectURL(path);
        } else if (path?.includes("http")) {
            return path;
        } else {
            return HOST + path;
        }
    } else {
        return Gallery;
    }
};
