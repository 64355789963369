import React, { useState, useEffect } from "react";
import FormPage, { CustomGridLayout } from "../../shared/Layouts/FormPage";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Accordion, AccordionTab } from "primereact/accordion";
import { viewSupportQueries } from "../../redux/actions/supportQueriesAction";
import moment from "moment";
import { newOrdersCount } from "../../redux/actions/dashboardAction";

const ViewSupportQuery = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [data, setData] = useState({
        user: {},
    });
    useEffect(() => {
        dispatch(newOrdersCount());
    }, [dispatch]);

    useEffect(() => {
        if (id) {
            dispatch(
                viewSupportQueries(id, (data) => {
                    setData({
                        user: data.user,
                        ...data,
                    });
                })
            );
        }
    }, [id, dispatch]);

    return (
        <>
            <FormPage>
                <CustomGridLayout>
                    <div className="col-12 md:col-5">
                        <div className="card">
                            <div className="flex align-items-center justify-content-between">
                                <h3>Support Query Information </h3>
                                <div className="flex align-items-center"></div>
                            </div>
                            <hr />
                            <div className="grid">
                                <p className="col-12">
                                    Name: {data.user?.firstName} {data.user?.lastName}
                                </p>
                                <p className="col-12">
                                    Email: <a href={`mailto:${data?.user?.email}`}> {data?.user?.email}</a>
                                </p>
                                <p className="col-12">
                                    Mobile: <a href={`tel:${data?.user?.mobile}`}> {data?.user?.mobile}</a>
                                </p>
                                <p className="col-12">
                                    Address:{" "}
                                    {data?.user?.address || data?.user?.city || data?.user?.state || data?.user?.pincode
                                        ? `${data?.user?.address ?? ""} ${data?.user?.city ?? ""} ${data?.user?.state ?? ""} ${
                                              data?.user?.pincode ?? ""
                                          }`
                                        : "N/A"}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-7 mx-auto ">
                        <Accordion className="" activeIndex={0}>
                            <AccordionTab header={`Support Query`}>
                                <p>Full Name: {data.fullName}</p>
                                <p>Email: {data.email}</p>
                                <p>Comments: {data.comments}</p>
                                <p>Time: {moment(data.time).format("LLL")}</p>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </CustomGridLayout>
            </FormPage>
        </>
    );
};

export default ViewSupportQuery;
