import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "/node_modules/primeflex/primeflex.css";
import "primereact/resources/themes/saga-orange/theme.css";
import "primereact/resources/primereact.min.css";
import "primeflex/themes/primeone-light.css";
import "primeicons/primeicons.css";

import "./style.scss";
import { HashRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import ToastContainer from "./shared/ToastContainer";
import store from "./redux";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import NotFound from "./pages/NotFound";
import { ConfirmPopup } from "primereact/confirmpopup";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ToastContainer />
            <ConfirmPopup />
            <HashRouter>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/forgetpassword" exact element={<ForgotPassword />} />
                    <Route path="/404" element={<NotFound />} />
                </Routes>
                <App />
            </HashRouter>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
