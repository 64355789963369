import React from "react";
import logoNew from "./assets/images/logoNew.png";

export const AppFooter = () => {
    return (
        <div className="layout-footer">
            <img src={logoNew} alt="Logo" height="20" className="mr-2" />

            {/* <span className="font-medium">
                SCAN<span style={{ color: "#ffdb4d" }}>ME</span>
            </span> */}
        </div>
    );
};
