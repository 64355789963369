import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAllExecutivePayroll } from "../../../redux/actions/executiveAction";
import { useDispatch, useSelector } from "react-redux";
import { CustomCalenderInput, CustomDropDown } from "../../../shared/AllInput";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import moment from "moment";

const ViewEarnings = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [data1, setData1] = useState({
        month: "",
        year: "",
        yearsOptions: [],
        plansData: [],
        assignedQRCodes: [],
        dateRange: [new Date(moment().subtract(6, "days")), new Date()],
    });

    const getQueryParams = () => {
        const params = new URLSearchParams(location.search);
        return {
            page: parseInt(params.get("page") || "1", 10),
            limit: parseInt(params.get("limit") || "10", 10),
            search: params.get("search") || "",
            status: params.get("status") || "",
        };
    };
    const { page: initialPage, limit: initialLimit } = getQueryParams();

    const [page, setPage] = useState(initialPage);
    const [limit, setLimit] = useState(initialLimit);
    useEffect(() => {
        const queryParams = new URLSearchParams();
        if (page) queryParams.set("page", page);
        if (limit) queryParams.set("limit", limit);
        navigate({ search: queryParams.toString() });
    }, [page, limit, navigate]);

    useEffect(() => {
        dispatch(getAllExecutivePayroll(page, limit, data1.dateRange));
    }, [id, dispatch, data1?.dateRange]);

    let allExecutivesPayroll = useSelector((state) => state.executive.allExecutivePayroll);
    const totalRecords = allExecutivesPayroll?.totalRecords || 0;

    const monthOptions = [];
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    for (let i = 0; i <= 11; i++) {
        const option = {
            name: `${months[i]}`,
            value: i + 1,
        };
        monthOptions.push(option);
    }
    const yearOptions = data1?.yearsOptions?.map((item) => ({ name: item, value: item }));

    const handleChange = ({ name, value }) => {
        console.log(name, value);
        setData1((prev) => ({ ...prev, [name]: value }));
    };
    const [pageSize, setPageSize] = useState(limit);
    const [currentPage, setCurrentPage] = useState(page);

    const onPageChange = (event) => {
        setCurrentPage(event.first / event.rows + 1);
        setPage(event.page + 1);
        setLimit(event.rows);
        setPageSize(event.rows);
    };
    const handleRowClick = (rowData) => {
        navigate(`/executive/view/${rowData.executiveId}`);
    };
    const [selectedCell, setSelectedCell] = useState(null);
    const [loading, setLoading] = useState(false);

    const onCellSelect = (event) => {
        console.log("event", event);
        if (event?.cellIndex === 0 || event?.cellIndex === 3) {
            navigate(`/executive/view/${event.rowData.executiveId}`);
        }
    };

    console.log("dateRange>>", data1);
    return (
        <>
            <div className="col-12 w-full flex">
                <div className="col-12">
                    <div className="card">
                        <div className="flex align-items-center justify-content-end">
                            <CustomCalenderInput
                                name="dateRange"
                                data={data1}
                                onChange={handleChange}
                                selectionMode="range"
                                // numberOfMonths={2}
                                hideOnRangeSelection
                                readOnlyInput
                                selectOtherMonths={true}
                                // showButtonBar={true}
                                extraClassName={"calendarWidth"}
                                maxDate={new Date()}
                                // onClearButtonClick={() =>
                                //     setData1((prev) => ({
                                //         ...prev,
                                //         dateRange: [new Date(moment().subtract(7, "days")), new Date()],
                                //     }))
                                // }
                            />
                        </div>
                        <DataTable
                            value={allExecutivesPayroll?.data}
                            loading={loading}
                            emptyMessage="No Executive Payroll found."
                            className=""
                            showGridlines
                            first={(currentPage - 1) * pageSize}
                            rows={pageSize}
                            totalRecords={totalRecords || 0}
                            cellSelection
                            selectionMode="single"
                            selection={selectedCell}
                            onSelectionChange={(e) => setSelectedCell(e.value)}
                            metaKeySelection={true}
                            onCellSelect={onCellSelect}
                        >
                            <Column
                                body={(rowData) => (
                                    <span style={{ cursor: "pointer" }} onClick={() => handleRowClick(rowData)}>
                                        {rowData.firstName ? rowData.firstName + " " + rowData.lastName : "-"}
                                    </span>
                                )}
                                header="Executive Name"
                                s
                            />
                            <Column field="qrCount" header="No. of QR Code" style={{ width: "250px" }}></Column>
                            <Column
                                field="totalCommission"
                                header="Commission"
                                body={(r) => `₹${r.totalCommission}`}
                                style={{ width: "250px" }}
                            ></Column>
                            <Column
                                field="totalEarnings"
                                body={(r) => (
                                    <span style={{ cursor: "pointer" }} onClick={() => handleRowClick(r)}>
                                        ₹ {r.totalEarnings}
                                    </span>
                                )}
                                header="Earnings"
                                style={{ width: "250px" }}
                            ></Column>

                            {/* <Column body={actions} style={{ width: "80px" }} header="Action"></Column> */}
                        </DataTable>
                        <Paginator
                            first={(currentPage - 1) * pageSize}
                            rows={pageSize}
                            totalRecords={totalRecords || 0}
                            onPageChange={onPageChange}
                            rowsPerPageOptions={[10, 20, 30]}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewEarnings;
