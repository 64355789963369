import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addVehicleBrand, editVehicleBrand, getVehicleBrand, getVehicleBrands } from "../../../redux/actions/vehicleBrandAction";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";

function VehicleBrandContainer() {
    const history = useNavigate();
    const dispatch = useDispatch();

    const { id } = useParams();

    const [data, setData] = useState({
        image: [],
        name: "",
        vehicleType: "4WHEELER",
    });
    const [formType, setFormType] = useState("EDIT");
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");

    let allVehicleBrands = useSelector((state) => state.vehicleBrand.allVehicleBrands);

    useEffect(() => {
        if (id) {
            dispatch(
                getVehicleBrand(id, (data) => {
                    setData({
                        image: data.image ? [data.image] : [],
                        name: data.name,
                        vehicleType: data.vehicleType,
                    });
                })
            );
        } else {
            dispatch(getVehicleBrands());
        }
    }, [id, dispatch]);

    const handleChange = ({ name, value }) => {
        if (formType !== "VIEW") {
            const formErrors = allValidations(name, value, data);
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const onSubmit = () => {
        if (showFormErrors(data, setData)) {
            if (formType === "ADD") {
                dispatch(addVehicleBrand(data, () => history("/vehicle-brand"), setLoading));
            } else if (formType === "EDIT") {
                dispatch(editVehicleBrand(id, data, dispatch, history, setLoading));
            }
        }
    };

    const getFilteredData = (data, search) => {
        if (search.length) {
            data = data?.filter((item) => item?.name?.toLowerCase().includes(search.toLowerCase()));
        }
        return data;
    };

    allVehicleBrands = getFilteredData(allVehicleBrands, search);
    allVehicleBrands = allVehicleBrands?.map((item) => ({
        ...item,
        time: new Date(item.time),
    }));

    const vehicleTypes = [
        { name: "4 WHEELER", value: "4WHEELER" },
        { name: "3 WHEELER", value: "3WHEELER" },
        // { name: "2 WHEELER", value: "2WHEELER" },
    ];

    return { data, handleChange, loading, onSubmit, setFormType, vehicleTypes };
}

export default VehicleBrandContainer;
