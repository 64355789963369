import api from "../../services/api";
import Constants from "../../services/constants";
import { updateUploadProgress } from "./uploadProgess";

export const uploadFiles = async (files) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
    }
    const res = await api("post", Constants.END_POINT.UPLOAD_FILES, formData);
    if (res.success) {
        if (res.data) {
            return res.data;
        }
    }
};

export const uploadImages = async (images, dispatch = () => {}) => {
    const promises = images.map(async (item) => {
        if (typeof item === "string") {
            return item;
        } else {
            const formData = new FormData();
            formData.append("file", item);
            const config = {
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    const percentCompleted = Math.round((loaded * 100) / total);
                    dispatch(updateUploadProgress(percentCompleted));
                },
            };
            // const res = await api("post", Constants.END_POINT.UPLOAD_FILE, formData);
            const res = await api("post", Constants.END_POINT.UPLOAD_FILE, formData, config, "multipart/form-data");
            if (res.success && res.data) {
                return res.data.path;
            }
        }
    });

    const urls = await Promise.all(promises);
    return urls;
};

export const uploadImage = async (images) => {
    const promises = images?.map(async (item) => {
        if (typeof item === "string") {
            return item;
        } else {
            const formData = new FormData();
            formData.append("file", item);
            const res = await api("post", Constants.END_POINT.UPLOAD_FILE, formData, {}, "multipart/form-data");
            if (res.success && res.data) {
                return res.data.path;
            }
        }
    });

    const urls = await Promise.all(promises);
    return urls;
};
