import React from "react";
import { useNavigate } from "react-router-dom";
import { capitalizeCamelCase } from "../../utils/commonFunctions";

export default function FormPage({ children, backText, backTo }) {
    const navigate = useNavigate();

    const onBack = () => {
        if (backTo) {
            navigate(backTo);
        } else {
            navigate(-1);
        }
    };
    return (
        <div>
            {backText ? (
                <div className="flex mb-3 text-dark-gray">
                    <i className="pi pi-angle-left text-2xl cursor-pointer" onClick={onBack}></i>
                    <div className=" my-auto font-semibold text-lg cursor-pointer" onClick={onBack}>
                        Back to {backText}
                    </div>
                </div>
            ) : (
                <div className="flex mb-3 text-dark-gray">
                    <i className="pi pi-angle-left text-2xl cursor-pointer" onClick={onBack}></i>
                    <div className=" my-auto font-semibold text-lg cursor-pointer" onClick={onBack}>
                        Go Back
                    </div>
                </div>
            )}
            <>{children}</>
        </div>
    );
}
export function CustomGridLayout({ children, extraClass }) {
    return <div className={`grid ${extraClass}`}>{children}</div>;
}
export function CustomViewCard({ title, children, col = 6, height }) {
    return (
        <div className={`col-12 md:col-${col}`}>
            <div className="bg-primary-dark border-round shadow-2 p-3">
                <div className="text-xl ">{title}</div>
            </div>
            <div className="bg-light-primary border-round p-2 mt-2 " style={{ height: height, overflowY: "auto", backgroundColor: "#dee6ed" }}>
                <div className="p-2">{children}</div>
            </div>
        </div>
    );
}
export function CustomListItem({ label, name, data, value }) {
    if (!label) {
        if (name) {
            label = capitalizeCamelCase(name);
        }
    }

    if (typeof value === "boolean") {
        if (value) {
            value = "Yes";
        } else {
            value = "No";
        }
    } else if (!value) {
        value = "-";
        if (typeof data[name] === "boolean" && data[name]) {
            value = "Yes";
        } else if (typeof data[name] === "boolean" && !data[name]) {
            value = "No";
        } else {
            value = data[name] ? data[name] : "-";
        }
    }

    return (
        <div className="flex justify-content-between text-sm mb-2">
            <span className="font-semibold ">{label}</span>
            <span className="text-dark-gray">{value}</span>
        </div>
    );
}
