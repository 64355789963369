import { Dialog } from "primereact/dialog";
import React from "react";
import PrimaryButton, { CustomButtonGroup, LightButton } from "../Button/PrimaryButton";

export default function CustomDialog({ title, visible, position, width = "30vw", onSave, loading, onCancel, children }) {
    const footerContent = (
        <CustomButtonGroup>
            {onSave ? <PrimaryButton label="Save" className="mx-2" loading={loading} onClick={onSave} /> : null}
            {onCancel ? <LightButton label="Cancel " className="my-2" onClick={onCancel} /> : null}
        </CustomButtonGroup>
    );
    return (
        <Dialog
            modal
            header={title}
            headerClassName="justify-content-start"
            visible={visible}
            style={{ width }}
            onHide={onCancel}
            footer={onSave || onCancel ? footerContent : ""}
            draggable={false}
            resizable={false}
            position={position}
        >
            {children}
        </Dialog>
    );
}
