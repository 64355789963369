import React, { useState } from "react";
import ForgetKey from "../assets/images/forget.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomCard from "../shared/Card/CustomCard";
import { allValidations } from "../utils/formValidations";
import { CustomInput, CustomPassword } from "../shared/AllInput";
import PrimaryButton from "../shared/Button/PrimaryButton";
import { ForgetPasswordAction, ResetForgotPassword } from "../redux/actions/loginAction";
import { showFormErrors } from "../utils/commonFunctions";
import Background from "../shared/Background/Background";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState();
    const [data, setData] = useState({
        email: "",
        otpCode: "",
        password: "",
        confirmPassword: "",
    });

    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const [forgetPassword, setForgetPassword] = useState();

    const handleForgot = (e) => {
        e.preventDefault();
        if (showFormErrors(data, setData, ["otpCode", "password", "confirmPassword"])) {
            dispatch(ForgetPasswordAction(data?.email, setLoading, setForgetPassword));
        }
    };
    const handleResetPassword = (e) => {
        e.preventDefault();
        if (showFormErrors(data, setData)) {
            dispatch(ResetForgotPassword(data, setLoading, navigate));
        }
    };

    const sendEmail = () => {
        return (
            <>
                <CustomCard backable={false} className="light z-1 relative">
                    <div className="text-center ">
                        <h2>
                            Forgot<span style={{ color: "#d4c526" }}>Password</span>
                        </h2>
                        <img src={ForgetKey} alt="logo" style={{ width: "100px" }} />
                    </div>
                    <form onSubmit={handleForgot}>
                        <CustomInput col="12" data={data} onChange={handleChange} name="email" label="Email" required />
                        <PrimaryButton label="Send OTP" loading={loading} type="submit" />
                    </form>

                    <div className="text-right mt-4">
                        <Link to="/" className="text-decoration-none">
                            &nbsp;
                            <span className="h6 navyColor font_bolder text-right">Go to Login</span>
                        </Link>
                    </div>
                </CustomCard>
            </>
        );
    };
    const OTPBox = () => {
        return (
            <CustomCard backable={false} className="light z-1 relative">
                <div className="text-center">
                    <h2>
                        Forgot<span style={{ color: "#d4c526" }}>Password</span>
                    </h2>
                    <img src={ForgetKey} alt="logo" style={{ width: "100px" }} />
                </div>
                <form onSubmit={handleResetPassword}>
                    <CustomInput col="12" data={data} onChange={handleChange} name="otpCode" label="Enter Your Otp" required keyfilter="int" />
                    <CustomPassword col="12" data={data} onChange={handleChange} name="password" label="Password" required />
                    <CustomPassword col="12" data={data} onChange={handleChange} name="confirmPassword" label=" Confirm Password" required />
                    <PrimaryButton label="Reset Password" loading={loading} type="submit" />
                </form>
            </CustomCard>
        );
    };
    return (
        <Background>
            <div className="grid mt-5">
                <div className="col-11 md:col-6 mx-auto">{forgetPassword ? OTPBox() : sendEmail()}</div>
            </div>
        </Background>
    );
};

export default ForgotPassword;
