import { types } from "../types/types";
const intitalState = {
    allSupportQueries: [],
};

const supportQueriesReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.SUPPORT_QUERIES:
            return {
                ...state,
                allSupportQueries: action.payload,
            };
        default:
            return { ...state };
    }
};

export default supportQueriesReducer;
