import React, { useEffect } from "react";
import FormPage from "../../../shared/Layouts/FormPage";
import ExecutiveContainer from "./ExecutiveContainer";
import CustomImageInput from "../../../shared/Input/CustomImageInput";
import PrimaryButton from "../../../shared/Button/PrimaryButton";
import { CustomForm, CustomInput, CustomRadioButtons } from "../../../shared/AllInput";
import QRCodeTable from "../../QRCode/QRCodeTable";
import CustomFileInput from "../../../shared/Input/CustomFileInput ";

function ExecutiveForm({ type }) {
    const {
        data,
        handleChange,
        loading,
        onSubmit,
        setFormType,
        assignedQRData,
        searchQR,
        setSearchQR,
        history,
        blockedOptions,
        page,
        limit,
        totalRecords,
        setPage,
        setLimit,
        setSelectedStatus,
        selectedStatus,
        id,
    } = ExecutiveContainer();

    useEffect(() => {
        setFormType(type);
    }, [type, setFormType]);

    return (
        <>
            <FormPage backTo={"/executive"}>
                <div className="col-12 w-full flex">
                    <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
                        <div className="col-12">
                            <div className="card">
                                <div className="flex align-items-center justify-content-between">
                                    <h3>Executive Information</h3>
                                </div>
                                <CustomRadioButtons
                                    col={12}
                                    options={blockedOptions}
                                    optionLabel={"name"}
                                    value={data.isBlocked}
                                    label="Is Blocked"
                                    name="isBlocked"
                                    data={data}
                                    onChange={handleChange}
                                />
                                <hr />
                                <div className="grid">
                                    <CustomImageInput
                                        name="image"
                                        label="Image"
                                        col={12}
                                        onFilesChange={handleChange}
                                        data={data}
                                        editable={type !== "VIEW" ? true : false}
                                    />
                                    <CustomInput col={12} label="First Name" name="firstName" required data={data} onChange={handleChange} />
                                    <CustomInput col={12} label="Last Name" name="lastName" data={data} onChange={handleChange} />
                                    <CustomInput col={12} label="Email" name="email" required data={data} onChange={handleChange} />
                                    {type !== "VIEW" && (
                                        <CustomInput col={12} label="Password" name="password" required data={data} onChange={handleChange} />
                                    )}
                                    {/* <CustomFileInput
                                        name="documents"
                                        label="Documents"
                                        col={12}
                                        onFilesChange={handleChange}
                                        data={data}
                                        editable={type !== "VIEW" ? true : false}
                                    /> */}
                                    <CustomInput col={6} placeholder="'Add Reference Name'" label="Reference 1" name="reference1" data={data} onChange={handleChange} />
                                    <CustomInput col={6} placeholder="'Add Reference Name'" label="Reference 2" name="reference2" data={data} onChange={handleChange} />
                                    <CustomFileInput accept=".pdf,.jpeg,.jpg,.png,.doc" extraClassName={"mt-2"} multiple limit={5} removeable onFilesChange={handleChange} col={12} label="Add Documents (max 5)" name="documents" data={data} editable={type !== "VIEW" ? true : false} />

                                    {type !== "VIEW" && (
                                        <div className="my-2">
                                            <PrimaryButton label="Save Executive" loading={loading} onClick={onSubmit} col={6} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </CustomForm>
                    {type === "VIEW" && (
                        <div className="col-12 md:col-6">
                            <div className="card">
                                <div className="flex align-items-center justify-content-between">
                                    <h3>Assigned QR</h3>
                                </div>
                                <div className="grid text-xl text-semibold m-0">{data.assignedCount}</div>
                            </div>
                            <div className="card">
                                <div className="flex align-items-center justify-content-between">
                                    <h3>Used QR</h3>
                                </div>
                                <div className="grid text-xl m-0">{data.activeCount}</div>
                            </div>
                            <div className="card">
                                {/* <div className="card" onClick={() => history(`/executive/view-earnings/${id}`)}> */}
                                <div className="flex align-items-center justify-content-between">
                                    <h3>Total Earnings</h3>
                                </div>
                                <div className="grid flex justify-content-between m-0 align-item-center text-xl">
                                    ₹ {data.totalRevenue}
                                    {/* <i className="pi pi-eye cursor-pointer text-dark"></i> */}
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {type === "VIEW" && (
                    <>
                        <QRCodeTable
                            heading="Executive QRCode"
                            allQRCode={assignedQRData}
                            loading={loading}
                            history={history}
                            search={searchQR}
                            setSearch={setSearchQR}
                            page={page}
                            limit={limit}
                            totalRecords={totalRecords}
                            setPage={setPage}
                            setLimit={setLimit}
                            setSelectedStatus={setSelectedStatus}
                            selectedStatus={selectedStatus}
                        />
                    </>
                )}
            </FormPage>
        </>
    );
}

export default ExecutiveForm;
