import { types } from "../types/types";

const intitalState = {
    dashboardData: [{ users: [] }, { revenue: [] }, { activeQrCodes: 0 }, { assignedQrCodes: 0 }, { executives: 0 }, { years: [] }],
    newOrdersCount: {},
};

const dashboardReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.DASHBOARD:
            return {
                ...state,
                dashboardData: action.payload,
            };

        case types.NEW_ORDERS_COUNT:
            return {
                ...state,
                newOrdersCount: action.payload,
            };
        default:
            return { ...state };
    }
};

export default dashboardReducer;
