import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addStaticPage, editStaticPage, getStaticPage, getStaticPages } from "../../../redux/actions/staticPageAction";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";

function StaticPageContainer() {
    const history = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    const [data, setData] = useState({
        title: "",
        content: "",
    });
    const [formType, setFormType] = useState("EDIT");
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");

    let allStaticPages = useSelector((state) => state.staticPage.allStaticPages);

    useEffect(() => {
        if (id) {
            dispatch(
                getStaticPage(id, (data) => {
                    setData((prev) => ({ ...prev, title: data.title, content: data.content }));
                })
            );
        } else {
            dispatch(getStaticPages());
        }
    }, [id, dispatch]);

    const handleChange = ({ name, value }) => {
        if (formType !== "VIEW") {
            const formErrors = allValidations(name, value, data);
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const onSubmit = () => {
        if (showFormErrors(data, setData)) {
            if (formType === "ADD") {
                dispatch(addStaticPage(data, () => history("/static-page"), setLoading));
            } else if (formType === "EDIT") {
                dispatch(editStaticPage(id, data, dispatch, history, setLoading));
            }
        }
    };

    const getFilteredData = (data, search) => {
        if (search.length) {
            data = data?.filter((item) => item?.name?.toLowerCase().includes(search.toLowerCase()));
        }
        return data;
    };

    allStaticPages = getFilteredData(allStaticPages, search);
    allStaticPages = allStaticPages?.map((item) => ({
        ...item,
        time: new Date(item.time),
    }));

    return { data, handleChange, loading, onSubmit, setFormType };
}

export default StaticPageContainer;
