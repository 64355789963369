import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { addExecutive, disableExecutive, editExecutive, getExecutive, getExecutives } from "../../../redux/actions/executiveAction";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";

function ExecutiveContainer() {
    const history = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { id } = useParams();

    const [data, setData] = useState({
        image: [],
        documents: [],
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        assignedQRCodes: [],
        isBlocked: { name: "No", key: false },
        reference1: "",
        reference2: "",
    });
    const [formType, setFormType] = useState("EDIT");
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");

    const getQueryParams = () => {
        const params = new URLSearchParams(location.search);
        return {
            page: parseInt(params.get("page") || "1", 10),
            limit: parseInt(params.get("limit") || "10", 10),
            search: params.get("search") || "",
            status: params.get("status") || "",
        };
    };

    const { page: initialPage, limit: initialLimit, search: initialSearch, status: initialStatus } = getQueryParams();

    const [page, setPage] = useState(initialPage);
    const [limit, setLimit] = useState(initialLimit);
    const [selectedStatus, setSelectedStatus] = useState(initialStatus);
    const [searchQR, setSearchQR] = useState(initialSearch);

    const [totalRecords, setTotalRecords] = useState(0);

    let allExecutives = useSelector((state) => state.executive.allExecutives);

    useEffect(() => {
        if (id) {
            dispatch(
                getExecutive(id, page, limit, searchQR, selectedStatus, (data) => {
                    setData({
                        assignedCount: data.assignedCount,
                        activeCount: data.activeCount,
                        totalRevenue: data.totalRevenue,
                        image: data.image ? [data.image] : [],
                        documents: data.documents ? data.documents : [],
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                        assignedQRCodes: data.assignedQRCodes ?? [],
                        isBlocked: data.isBlocked === true ? { name: "Yes", key: true } : { name: "No", key: false },
                        reference1: data.reference1,
                        reference2: data.reference2,
                    });
                    setTotalRecords(data.assignedCount);
                })
            );
        } else {
            dispatch(getExecutives());
        }
    }, [id, dispatch, page, limit, searchQR, selectedStatus]);

    console.log(data);
    useEffect(() => {
        const queryParams = new URLSearchParams();
        if (page) queryParams.set("page", page);
        if (limit) queryParams.set("limit", limit);
        if (searchQR) queryParams.set("search", searchQR);
        if (selectedStatus) queryParams.set("status", selectedStatus);
        history({ search: queryParams.toString() });
    }, [page, limit, searchQR, selectedStatus, history]);

    const handleChange = ({ name, value }) => {
        if (formType !== "VIEW") {
            const formErrors = allValidations(name, value, data);
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
            if (name === "isBlocked") {
                dispatch(disableExecutive(id, value.key));
            }
        }
    };

    const onSubmit = () => {
        if (showFormErrors(data, setData)) {
            if (formType === "ADD") {
                dispatch(addExecutive(data, () => history("/executive"), setLoading));
            } else if (formType === "EDIT") {
                dispatch(editExecutive(id, data, dispatch, history, setLoading));
            }
        }
    };

    const getFilteredData = (data, search) => {
        if (search.length) {
            data = data?.filter((item) => item?.name?.toLowerCase().includes(search.toLowerCase()));
        }
        return data;
    };

    const getFilteredQRData = (data, search) => {
        if (search.length) {
            const lowerSearch = search.toLowerCase();
            data = data.filter(
                (item) =>
                    (item.status && item.status.toLowerCase().includes(lowerSearch)) ||
                    (item.srNo && item.srNo.toString().toLowerCase().includes(lowerSearch))
            );
        }
        return data;
    };

    allExecutives = getFilteredData(allExecutives, search);
    allExecutives = allExecutives?.map((item) => ({
        ...item,
        time: new Date(item.time),
    }));

    let assignedQRData = getFilteredQRData(data.assignedQRCodes, search);
    assignedQRData = assignedQRData?.map((item) => ({
        ...item,
        time: new Date(item.time),
    }));

    let blockedOptions = [
        { name: "Yes", key: true },
        { name: "No", key: false },
    ];

    return {
        data,
        handleChange,
        loading,
        onSubmit,
        setFormType,
        assignedQRData,
        setSearch,
        search,
        searchQR,
        setSearchQR,
        history,
        blockedOptions,
        page,
        limit,
        setPage,
        setLimit,
        totalRecords,
        selectedStatus,
        setSelectedStatus,
        id,
    };
}

export default ExecutiveContainer;
