import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import TableImage from "../../shared/Table/TableImage";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import PrimaryButton from "../../shared/Button/PrimaryButton";
import { Dialog } from "primereact/dialog";
import SampleFile from "../../assets/path/sample_file_vehicle_brand.xlsx";
import { useDispatch } from "react-redux";
import { importVehicleBrand } from "../../redux/actions/vehicleBrandAction";

import * as XLSX from "xlsx";
import { parse } from "papaparse";

export default function VehicleBrandTable({ heading, allVehicleBrands, loading, setLoading, history, search, setSearch, onDelete }) {
    const [selectedRow, setSelectedRow] = useState([]);
    const [openImport, setOpenImport] = useState(false);

    const actions = (d) => {
        return (
            <div className="flex justify-content-around">
                <i className="pi pi-eye cursor-pointer text-dark" onClick={() => history(`/vehicle-brand/view/${d._id}`)}></i>
                <i className="pi pi-pencil cursor-pointer text-dark ml-2 mr-2" onClick={() => history(`/vehicle-brand/edit/${d._id}`)}></i>
                <i className="pi pi-trash cursor-pointer text-dark" onClick={() => onDelete(d._id)}></i>
            </div>
        );
    };

    const image = (r) => {
        const image = r.image;
        return <TableImage image={image} />;
    };
    const dateFilterTemplate = (options) => {
        return (
            <Calendar
                value={options.value}
                onChange={(e) => {
                    options.filterCallback(e.value, options.index);
                }}
                dateFormat="mm/dd/yy"
                placeholder="mm/dd/yyyy"
                mask="99/99/9999"
            />
        );
    };
    const filters = {
        time: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        subscriptionExpireOn: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    };
    const formatDate = (date) => {
        return date?.toLocaleDateString?.("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
    };
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFile(file);
    };
    const dispatch = useDispatch();

    const handleSave = async () => {
        if (file) {
            // Check file type and parse accordingly
            if (file.name.endsWith(".csv")) {
                parseCSV(file);
            } else if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
                parseXLSX(file);
            } else {
                alert("Unsupported file format. Please select a CSV or XLSX file.");
            }
        }
    };

    const parseCSV = (file) => {
        setLoading(true);
        const reader = new FileReader();
        reader.onload = (e) => {
            const csv = e.target.result;
            // Parse CSV using PapaParse library
            parse(csv, {
                header: true,
                complete: (result) => {
                    let data = result.data;
                    // Filter out empty rows and convert to array of arrays
                    data = data?.filter((row) => row.name && row.vehicleType);

                    // Extract headers
                    const headers = Object.keys(data?.[0]);

                    // Convert objects to arrays and prepend headers as the first row
                    const brandsData = [headers, ...data?.map((row) => headers.map((header) => row[header]))];

                    // Dispatch action to import data
                    dispatch(importVehicleBrand({ brandsData }, setOpenImport));
                },
                error: (err) => {
                    console.error("Error parsing CSV:", err);
                    alert("Error parsing CSV file.");
                },
            });
        };
        reader.readAsText(file);
        setLoading(false);
    };

    // Function to parse XLSX file
    const parseXLSX = (file) => {
        setLoading(true);
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            // Convert XLSX sheet to JSON
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            // Dispatch action to import data
            dispatch(importVehicleBrand({ brandsData: jsonData }, setOpenImport));
        };
        reader.readAsArrayBuffer(file);
        setLoading(false);
    };

    return (
        <>
            <div className="col-12">
                <div className="card">
                    <div className="flex justify-content-between mb-4">
                        <h5>{heading}</h5>
                        {setSearch ? (
                            <div className="p-input-icon-left mx-2">
                                <i className="pi pi-search" />
                                <InputText value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search" />
                                <PrimaryButton onClick={() => history(`/vehicle-brand/add`)}>Add Vehicle Brand</PrimaryButton>
                                <PrimaryButton onClick={() => setOpenImport(true)}>Import</PrimaryButton>
                            </div>
                        ) : null}
                    </div>
                    <DataTable
                        paginator
                        value={allVehicleBrands}
                        selection={selectedRow}
                        onSelectionChange={(e) => setSelectedRow(e.value)}
                        selectionMode="checkbox"
                        filter={filters}
                        filterDisplay="menu"
                        rows={10}
                        loading={loading}
                        emptyMessage="No Vehicle Brand found."
                        className=""
                        showGridlines
                    >
                        <Column field="name" header="Name" style={{ width: "250px" }}></Column>
                        <Column
                            field="vehicleType"
                            header="Vehicle Type"
                            style={{ width: "250px" }}
                            body={(rowData) => {
                                let formattedType = rowData.vehicleType;
                                if (rowData.vehicleType === "4WHEELER") {
                                    formattedType = "4 WHEELER";
                                } else if (rowData.vehicleType === "3WHEELER") {
                                    formattedType = "3 WHEELER";
                                    // } else if (rowData.vehicleType === "2WHEELER") {
                                    //     formattedType = "2 WHEELER";
                                }
                                return formattedType;
                            }}
                        ></Column>
                        <Column
                            header="Date"
                            field="time"
                            dataType="date"
                            style={{ width: "150px" }}
                            filterMatchMode="dateIs"
                            showFilterMatchModes={true}
                            body={(rowData) => formatDate(rowData.time)}
                            filter
                            filterElement={dateFilterTemplate}
                        />
                        <Column body={image} header="Image" style={{ width: "80px" }}></Column>
                        <Column body={actions} style={{ width: "80px" }} header="Action"></Column>
                    </DataTable>
                    <Dialog
                        header=""
                        className=""
                        visible={openImport}
                        contentClassName="flex flex-column align-items-center"
                        style={{ width: "50vw" }}
                        onHide={() => setOpenImport(false)}
                    >
                        <input
                            type={"file"}
                            name="csvFileInput"
                            id="csvFileInput"
                            accept=".xlsx, .xls , .csv"
                            onChange={handleFileChange}
                            className=""
                        />

                        <div className="flex mt-4">
                            <lable>Please Dowload the sample file</lable>
                            <a href={SampleFile} download="sample-vehicle-brand.xlsx" className="ml-2">
                                Sample File
                            </a>
                        </div>

                        <PrimaryButton label="Save" className="mt-4" loading={loading} onClick={handleSave} />
                    </Dialog>
                </div>
            </div>
        </>
    );
}
