import { types } from "../types/types";
const intitalState = {
    allVehicleBrands: [],
};

const vehicleBrandReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.VEHICLE_BRANDS:
            return {
                ...state,
                allVehicleBrands: action.payload,
            };
        default:
            return { ...state };
    }
};

export default vehicleBrandReducer;
