import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getReplacements } from "../../redux/actions/replacementAction";
import ReplacementTable from "./ReplacementTable";

const Replacement = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getReplacements(setLoading));
    }, [dispatch]);

    let allReplacement = useSelector((state) => state.replacement.allReplacement);

    const [loading, setLoading] = useState(false);

    const [search, setSearch] = useState("");
    const getFilteredData = (data, search) => {
        if (search.length) {
            data = data?.filter((item) => item?.reason?.includes(search) || item?.comments?.includes(search));
        }
        return data;
    };

    allReplacement = getFilteredData(allReplacement, search);
    allReplacement = allReplacement?.map((item) => ({
        ...item,
        time: new Date(item.time),
    }));

    return (
        <ReplacementTable
            heading="All Replacements"
            allReplacement={allReplacement}
            loading={loading}
            history={history}
            search={search}
            setSearch={setSearch}
        />
    );
};

export default Replacement;
