import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ExecutiveTable from "./ExecutiveTable";
import { getExecutives, deleteExecutive } from "../../redux/actions/executiveAction";
import { confirmDelete, customConfirmPopup } from "../../utils/commonFunctions";

const Executive = () => {
    const history = useNavigate();
    const dispatch = useDispatch();

    let allExecutives = useSelector((state) => state.executive.allExecutives);

    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");

    useEffect(() => {
        dispatch(getExecutives(setLoading));
    }, [dispatch]);

    const getFilteredData = (data, search) => {
        if (search.length) {
            data = data?.filter(
                (item) =>
                    item?.firstName?.toLowerCase().includes(search.toLowerCase()) ||
                    item?.lastName?.toLowerCase().includes(search.toLowerCase()) ||
                    item?.email?.toLowerCase().includes(search.toLowerCase())
            );
        }
        return data;
    };

    allExecutives = getFilteredData(allExecutives, search);
    allExecutives = allExecutives.map((item) => ({
        ...item,
        time: new Date(item.time),
    }));

    const onDelete = (id) => {
        customConfirmPopup(
            () => dispatch(deleteExecutive(id)),
            "Do you want to delete this record?",
        );
    };

    return <ExecutiveTable heading="All Executives" allExecutives={allExecutives} loading={loading} history={history} search={search} setSearch={setSearch} onDelete={onDelete} />;
};

export default Executive;
